<template>
    <ion-text>
        {{ location.displayString }}
    </ion-text>
</template>

<script setup lang="ts">
    const props = defineProps({
        location: {
            type: Object,
            required: true,
        },
    });

    defineExpose({
        latlng
    })

    function latlng(){
        if(! props.location.place) return undefined
        return [props.location.place?.geometry.coordinates[1], props.location.place?.geometry.coordinates[0]]
    }
</script>

<style scoped>
</style>
