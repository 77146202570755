<template>
    <ion-input ref="usernameInput" type="text" :placeholder="$t('username')" v-model="username" @ionInput="verifyUsername(); $emit('update:username', $event.target.value)"
            :error-text="usernameError" class="signup-input">
        <ion-icon slot="start" :icon="person" size="large"></ion-icon>
    </ion-input>
    <div v-if="userNameSpecialCharError">
        <ion-text color="warning">
            {{ $t('dot') }}<ion-chip color="medium" outline="true"><ion-label class="char-allowed">.</ion-label></ion-chip>
            {{ $t('dash') }}<ion-chip color="medium" outline="true"><ion-label class="char-allowed">-</ion-label></ion-chip>
            {{ $t('underscore') }}<ion-chip color="medium" outline="true"><ion-label class="char-allowed">_</ion-label></ion-chip>
        </ion-text>
    </div>
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { userAPI } from '@/libs/backend';
    import { showIonInputError } from '@/libs/userExperience';
    import { person } from 'ionicons/icons';

    defineEmits(['update:username'])

    defineExpose({
        verifyUsername,
        hasError
    });

    const i18n = useI18n();

    const username = ref('');
    const usernameError = ref('');
    const userNameSpecialCharError = ref(false);

    // HTML elements refs
    const usernameInput = ref();

    async function verifyUsername(){
        userNameSpecialCharError.value = false;
        
        if (username.value.length == 0){
            usernameError.value = i18n.t('Required field') 
        }
        else if (username.value.length < 4){
            usernameError.value = i18n.t('Username is minimum 4 characters') 
        }
        else if ( ! /^[a-zA-Z0-9\.\-_]*$/.test(username.value)) {
            userNameSpecialCharError.value = true
            usernameError.value = i18n.t('Only those special characteres are allowed')
        }
        else if(username.value.length >= 4){
            userAPI.nextErrorToastDisabled = true;
            let data = await userAPI.verifyUsername(username.value);
            if( ! data.success){
                usernameError.value = i18n.t('Username is already used')     
            }
            else{
                usernameError.value = '';    
            }
        }
        else{
            usernameError.value = '';
        }

        let error:boolean = hasError()
        showIonInputError(usernameInput, error);

        return error;
    }

    function hasError(){
        return (usernameError.value != '' || userNameSpecialCharError.value == true)
    }
</script>

<style scoped>
@import '@/theme/login.css';

.char-allowed{
    font-family: Verdana, Geneva, Tahoma, sans-serif !important;
    font-size: 15px;
    font-weight: bold;
}
</style>