<template>
    {{ location.properties.full_address }}
</template>

<script setup lang="ts">
    const props = defineProps({
        location: {
            type: Object,
            required: true,
        },
    });

    defineExpose({
        latlng
    })

    function latlng(){
        return [props.location.geometry.coordinates[1], props.location.geometry.coordinates[0]]
    }
</script>
