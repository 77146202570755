<template>
    {{ location.displayName.text }}, {{ location.formattedAddress }}
</template>

<script setup lang="ts">
    const props = defineProps({
        location: {
            type: Object,
            required: true,
        },
    });

    defineExpose({
        latlng
    })

    function latlng(){
        return [props.location.location.latitude, props.location.location.longitude]
    }
</script>
