<template>
    {{ location.display_name }}
</template>

<script setup lang="ts">
    const props = defineProps({
        location: {
            type: Object,
            required: true,
        },
    });

    defineExpose({
        latlng
    })

    function latlng(){
        return [parseFloat(props.location.lat), parseFloat(props.location.lon)]
    }
</script>
