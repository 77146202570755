import router from '@/router';
import { authAPI, userAPI } from '@/libs/backend'
import { AuthStore } from '@/stores/AuthStore'


export async function login(username:string, password:string) {
    return authAPI.login(username, password).then(
        (data:any)=>{
            if(data && data.success == false){
                return data // login error reason
            }
            else if(data && data.access_token){
                postLoginAction(data.access_token)
                return data
            }
            else{
                // TODO : user inteface actions ??
                console.error("Invalid token")
            }
        },
        (error:any)=>{
            // TODO : >= 400 Error seems to not go here :/
        }
    )
}

export function postLoginAction(accessToken:string){
    if(accessToken){
        const authStore = AuthStore();
        authStore.setToken(accessToken);
        userAPI.userInfos().then((userInfos)=>{
            console.log(userInfos)
            authStore.setUserData(userInfos)
            router.push('/');
        })
    }
}

export function isLoggedIn(){
    const authStore = AuthStore();
    return (authStore.token())
}

export async function logout() {
    const authStore = AuthStore();
    authAPI.logout().then(
        ()=>{
            authStore.clear();
            router.push('/login');
        },
        (error:any)=>{
            // TODO : >= 400 Error seems to not go here :/
        }
    )
}