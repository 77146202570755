<template>
    <l-popup ref="popover" v-element-visibility="initPopup" 
        :options="{
            className:'wp-popover dark-popup '+(isContextMenu?'context-menu':''), 
            closeButton:false, 
            minWidth:240, 
            maxWidth:240
        }"
    >
        <PopoverHeader></PopoverHeader>
        
        <PopoverProfileSelect v-if="profileSelect && (isStart || isDestination || isStep)" ref="popoverProfile"></PopoverProfileSelect>
        
        <PopoverStartOptions v-if="isStart"></PopoverStartOptions>
        <PopoverStepOptions v-if="isStep"></PopoverStepOptions>
        <PopoverDestinationOptions v-if="isDestination"></PopoverDestinationOptions>
        <PopoverContextMenuOptions v-if="isContextMenu || isPlace || isCurrentLocation" ref="popoverContextMenuOptions"></PopoverContextMenuOptions>
        <PopoverCurrentLocationOptions v-if="isCurrentLocation"></PopoverCurrentLocationOptions>
        <PopoverPlaceOptions v-if="isPlace"></PopoverPlaceOptions>
    </l-popup>
</template>

<script setup lang="ts">
    import { LPopup } from "@vue-leaflet/vue-leaflet";
    import { ref, onUpdated } from 'vue';
    import { roadbookEditStore } from '@/stores/RoadbookEditStore';
    import { vElementVisibility } from '@vueuse/components'
    import PopoverHeader from "@/components/MapPopover/PopoverHeader.vue";
    import { provide } from 'vue'
    import PopoverProfileSelect from "./MapPopover/PopoverProfileSelect.vue";
    import PopoverStartOptions from "./MapPopover/PopoverStartOptions.vue";
    import PopoverStepOptions from "./MapPopover/PopoverStepOptions.vue";
    import PopoverDestinationOptions from "./MapPopover/PopoverDestinationOptions.vue";
    import PopoverContextMenuOptions from "./MapPopover/PopoverContextMenuOptions.vue";
    import PopoverCurrentLocationOptions from "./MapPopover/PopoverCurrentLocationOptions.vue";
    import PopoverPlaceOptions from "./MapPopover/PopoverPlaceOptions.vue";
    
    const roadbookStore = roadbookEditStore();
       
    // HTML components refs
    const popover = ref();
    const popoverProfile = ref();
    const popoverContextMenuOptions = ref();
    
    const props = defineProps({
        latLon:{
            type: Array,
            required: true
        },
        autoOpen: {
            type: Boolean,
            default: false
        },
        stepNumber: {
            type: Number,
            default: null
        },
        isStart: {
            type: Boolean,
            default: false
        },
        isDestination: {
            type: Boolean,
            default: false
        },
        isStep: {
            type: Boolean,
            default: false
        },
        isContextMenu: {
            type: Boolean,
            default: false
        },
        isCurrentLocation: {
            type: Boolean,
            default: false
        },
        isPlace: {
            type: Boolean,
            default: false
        },
        profileSelect: {
            type: Boolean,
            default: true,
        }
    });

    const emit = defineEmits([
        'dismiss'
    ]);
    
    defineExpose({
        dismiss,
    });

    let previousLatLng:any = null;
    onUpdated(()=>{
        popover.value.leafletObject.close();
        if(props.autoOpen && previousLatLng != props.latLon) {
            popover.value.leafletObject.toggle();
            previousLatLng = props.latLon;
        }
    });

    const popoverType = ref({
        isStart:props.isStart,
        isDestination:props.isDestination,
        isStep:props.isStep,
        isContextMenu:props.isContextMenu,
        isCurrentLocation:props.isCurrentLocation,
        isPlace: props.isPlace
    });

    provide('popoverActions', {dismiss, coordinates, removeWaypoint, popoverType, stepNumber:props.stepNumber});
    

    function initPopup(visible:boolean) {
        roadbookStore.popoverOpened = visible;
        if(visible){
            // get router profile for this waypoint
            coordinates();

            // Update sub components if present
            if(popoverProfile.value) popoverProfile.value.update();
            if(popoverContextMenuOptions.value) popoverContextMenuOptions.value.update();
        }
    }
    
    function coordinates(){
        return latlng()
        // let leafletLatLng = popover.value.leafletObject._latlng;
        // return [leafletLatLng.lat, leafletLatLng.lng];
    }
    
    function dismiss(event:(Event|null)=null){
        if(event){
            event.stopPropagation()
        }
        popover.value.leafletObject.close();
        emit('dismiss')
    }

    function latlng(){
        return props.latLon;
    }



    function removeWaypoint(){
        dismiss();
        roadbookStore.removeWaypoint(latlng())
    }



    
</script>

<style>
    /* THIS CSS IS INJECTED GLOBAL CSS : no 'scoped' mentioned in <style> tag */

    .wp-popover ion-item {
        --padding-start: 15px;
        --padding-end: 0px;
        --inner-padding-end: 0px;
        --inner-padding-start: 0px;
        min-height: 42px;
    }

    .step-number-input{
        --border-radius: 8px !important;
        --background: #fff;
        --color: var(--ion-component-background);
        --placeholder-color: var(--ion-component-background);
        --placeholder-opacity: 0.8;
        font-size: large;
        height: 30px !important;
        min-height: 30px !important;
        max-height: 30px !important;
        width: 50px !important;
        min-width: 50px !important;
        max-width: 50px !important;
        line-height: 30px !important;
        margin-top: 8px !important;
        text-align: center;
    }

    .leaflet-popup.dark-popup .leaflet-popup-content-wrapper, 
    .leaflet-popup.dark-popup .leaflet-popup-tip {
        background-color: var(--ion-color-anthracite) !important;
        color: var(--ion-color-anthracite-contrast) !important;
    }

    .leaflet-popup.dark-popup.context-menu .leaflet-popup-content-wrapper, 
    .leaflet-popup.dark-popup.context-menu .leaflet-popup-tip {
        border: 3px outset var(--ion-color-tertiary)
    }

    .leaflet-popup.dark-popup .leaflet-popup-content{
        margin: 5px 5px 5px 5px !important;
        border-radius: 12px;   
    }
</style>