<template>
    <l-layer-group>
        <l-polyline v-for="section of pointsSections" :lat-lngs="sectionPoints(section)" :color="profileColor(section)"/>
    
        <l-circle-marker :visible="props.displayPicker && showTracePicker" :lat-lng="traceWaypointPicker"
            :radius="10" color="#f5c211" fillColor="#f5c211" :fillOpacity="1" @click="insertWaypoint($event)">
        </l-circle-marker>
    </l-layer-group>
</template>

<script setup lang="ts">
    import "leaflet";
    import { ref, watch, toRaw, onMounted } from "vue";
    import { LLayerGroup, LPolyline, LCircleMarker } from "@vue-leaflet/vue-leaflet";
    import { roadbookEditStore } from "@/stores/RoadbookEditStore";
    import nearestPointOnLine from '@turf/nearest-point-on-line';
    import pointToLineDistance from '@turf/point-to-line-distance';
    import {point, lineString } from '@turf/helpers';

    const traceWaypointPicker:any = ref([0,0]);
    const showTracePicker = ref(false)
    const roadbookStore = roadbookEditStore();
    const pointsLine:any = ref(undefined)
    const sectionLines:any = ref([])
    const nearestPoint:any = ref(null);

    const props = defineProps({
        points: {
            type: Array<Array<Number>>,
            required: true
        },
        pointsSections: {
            type: Array<Array<Number|String>>,
            required: true
        },
        displayPicker: {
            type: Boolean,
            default: true
        },
        // props below are used to define minimal distance to display trace cursor circle
        cursorPosition: {
            type: Object
        },
        zoomLevel: {
            type: Number
        }
    });

    watch(() => props.points, () => {
        createLineStrings();
    });

    watch(() => props.cursorPosition, () => {
        if(!props.cursorPosition || !props.zoomLevel) return;
        
        let cursorPoint = point([props.cursorPosition.lat, props.cursorPosition.lng])
        
        if(! pointsLine.value) return;

        let distance = pointToLineDistance(cursorPoint, pointsLine.value) * 1000; // to metter wit *1000
        
        let compareDistance = 0;
        if(props.zoomLevel >= 18) compareDistance = 5
        else if(props.zoomLevel > 17) compareDistance = 15
        else if(props.zoomLevel > 15) compareDistance = 25
        else if(props.zoomLevel > 14) compareDistance = 50
        else if(props.zoomLevel > 13) compareDistance = 100
        else if(props.zoomLevel > 12) compareDistance = 300
        else if(props.zoomLevel > 11) compareDistance = 800

        if(props.zoomLevel > 11 && distance < compareDistance){
            let ret = nearestPointOnLine(pointsLine.value, cursorPoint)
            traceWaypointPicker.value = ret.geometry.coordinates
            nearestPoint.value = ret.geometry.coordinates;
            showTracePicker.value = true;
        }
        else{
            showTracePicker.value = false;
        }
    });

    onMounted(()=>{
        createLineStrings();
    });

    function createLineStrings(){
        // Create full linestring once per 'points' modification
        pointsLine.value = pointsToLineString(props.points)

        sectionLines.value = []
        for(let section of props.pointsSections){
            sectionLines.value.push(pointsToLineString(sectionPoints(section))) 
        }
    }

    function pointsToLineString(points:any){
        let rawPoints:any = [];
        for(let point of points){
            rawPoints.push(toRaw(point));
        }
        return lineString(rawPoints)
    }

    function sectionPoints(section:any){
        return props.points.slice(section[0],section[1]+1) // slice use length style as second argument, so we add +1
    }

    function profileColor(section:any){
        if(section[2] == 'highway' || section[2] == 'fastest') return '#0080a9'
        else if(section[2] == 'no_highway' || section[2] == 'curvy') return '#008000'
        else if(section[2] == 'super_curvy') return '#d45500'
        else return 'black'
    }

    function insertWaypoint(e:any){
        L.DomEvent.stopPropagation(e); // prevent event propagation
        if(nearestPoint.value){
            // Get closest section
            let dist = undefined;
            let foundIndex:number = 0;
            for(let index=0; index < sectionLines.value.length ; index++){
                let distToSection = pointToLineDistance(toRaw(nearestPoint.value), toRaw(sectionLines.value[index]));
                if(dist==undefined || distToSection < dist){
                    dist = distToSection;
                    foundIndex = index+1;
                }
            }
            roadbookStore.insertWaypoint([e.latlng.lat, e.latlng.lng], foundIndex)
        }
    }

</script>

<style scoped>
</style>