<template>
    <ion-toolbar>
        <ion-button shape="rounded" size="large" slot="start" @click="sitchToSelect" class="ion-margin-start ion-margin-end" :fill="selectionEnabled?'outline':'clear'" color="light">
            <ion-icon slot="start" :icon="selectionEnabled ? close:albumsOutline"></ion-icon>
            {{ selectionEnabled ? $t('Cancel') : $t('Select')}}
        </ion-button>
        <ion-button v-if="selectionEnabled" :disabled="!selectedRoadbooks.length" slot="start" @click="tagSelected" size="large" class="bold ion-margin-end" fill="outline" >
            <ion-icon slot="start" :icon="pricetagOutline"></ion-icon>
            {{$t('Category')}}
        </ion-button>
        <ion-button v-if="selectionEnabled" :disabled="!selectedRoadbooks.length" slot="start" @click="archiveSelected" size="large" class="bold ion-margin-end" >
            <ion-icon slot="start" :icon="archiveOutline"></ion-icon>
            {{$t('Archive')}}
        </ion-button>
        <ion-button v-if="selectionEnabled" :disabled="!selectedRoadbooks.length" slot="start" @click="deleteSelected" size="large" class="bold ion-margin-end" color="danger">
            <ion-icon slot="start" :icon="trashBinOutline"></ion-icon>
            {{$t('Delete')}}
        </ion-button>

        <ion-label slot="end" class="ion-margin-end">{{ $t('Display') }}</ion-label>
        <ion-segment v-model="displayMode" slot="end" value="list" @ionChange="" class="icon-segment">
                <ion-segment-button value="list">
                    <ion-icon :icon="list"></ion-icon>
                </ion-segment-button>
                <ion-segment-button value="thumbnail">
                    <ion-icon :icon="image"></ion-icon>
                </ion-segment-button>
        </ion-segment>
        <ion-label slot="end" class="ion-margin-start">{{ $t('Size') }}</ion-label>
        <ion-buttons slot="end">
            <ion-button @click="toggleCardSize">
                <ion-icon slot="icon-only" :icon="removeCircleOutline"></ion-icon>
            </ion-button>
            <ion-button @click="toggleCardSize">
                <ion-icon slot="icon-only" :icon="addCircleOutline"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
    <ion-row class="ion-margin-start">
        <ion-col size="12" class="ion-margin-top ion-padding-start">
            <ion-text >
                <h1>{{ $t(selectedCategory) }}</h1>
            </ion-text>
        </ion-col>
    </ion-row>
    <ion-row>
        <ion-col sizeLg="12" sizeXl="8" class="ion-no-padding">
            <div v-if="displayMode == 'list'">
                <ion-card v-if="displayedRoadbooks.length">
                    <ion-card-content>
                        <ion-list>
                            <ion-item v-if="selectionEnabled">
                                <ion-button v-if="selectedRoadbooks.length != displayedRoadbooks.length" @click="selectAll" fill="outline" color="light" slot="end">
                                    {{$t('Select all')}}
                                </ion-button>
                                <ion-button v-if="selectedRoadbooks.length == displayedRoadbooks.length" @click="selectedRoadbooks = []" fill="outline" color="light" slot="end">
                                    {{$t('Unselect all')}}
                                </ion-button>
                            </ion-item>
                            <ion-item v-for="roadbook of displayedRoadbooks" button @click="selectionEnabled ? select($event, roadbook):editRoadook(roadbook)">
                                <ion-icon :icon="roadbook.roundtrip ? reloadOutline:analyticsOutline" color="tertiary"></ion-icon>
                                <ion-text class="ion-margin-start ion-margin-end">{{ roadbook.title }}</ion-text>

                                <ion-chip v-if="devicesStore.isRoadbookUploadPending(roadbook.id)" slot="end" color="primary" mode="ios" outline="true">
                                    <ion-icon  :icon="cloudUploadOutline"></ion-icon>
                                    <ion-label>{{$t('upload')}}</ion-label>
                                </ion-chip>

                                <ion-chip v-if="selectedCategory == 'all' && roadbook.favorite" color="lights" mode="ios" outline="true" slot="end">
                                    <ion-icon :icon="heart" color="danger"></ion-icon>
                                    <ion-label>{{ $t('favorite') }}</ion-label>
                                </ion-chip>
                                <ion-buttons v-if="!selectionEnabled" slot="end" @click="$event.stopPropagation()">
                                    <ion-button :id="'roadbook-'+roadbook.id">
                                        <ion-icon slot="icon-only" :icon="ellipsisVertical"></ion-icon>
                                    </ion-button>
                                </ion-buttons>
                                <ion-icon v-if="selectionEnabled && isSelected(roadbook)" slot="end" :icon="checkboxOutline" color="tertiary"></ion-icon>
                                <ion-icon v-else-if="selectionEnabled && !isSelected(roadbook)" slot="end" :icon="squareOutline" color="light"></ion-icon>
                            </ion-item>
                        </ion-list>
                    </ion-card-content>
                </ion-card>
                <div v-show="!displayedRoadbooks.length" class="div-center ion-text-center no-result">
                    <Vue3Lottie ref="lottieNoResult" :animationData="NoResult" :autoPlay="false" :loop="false" />
                    <ion-text><h1>{{ $t('No roadbook for') }} {{ $t(selectedCategory) }} {{ $t('section') }}</h1></ion-text>
                </div>
            </div>
            <ion-grid v-if="displayMode == 'thumbnail'">
                <ion-row>
                    <ion-col v-for="roadbook in displayedRoadbooks" :sizeMd="isDesktop?cardSize:12">
                        <ion-card @click="select($event, roadbook)" :class="{selectable: (selectionEnabled == true), selected:(isSelected(roadbook))}" button>
                            <ion-item lines="none">
                                <ion-icon v-if="selectionEnabled && isSelected(roadbook)" slot="start" :icon="checkboxOutline" color="tertiary"></ion-icon>
                                <ion-icon v-else-if="selectionEnabled && !isSelected(roadbook)" slot="start" :icon="squareOutline" color="light"></ion-icon>
                                <ion-label>
                                    {{ roadbook.title }}
                                    <ion-chip color="primary" mode="ios" class="ion-no-margin">
                                        <ion-label>{{$t(roadbook.type)}}</ion-label>
                                    </ion-chip>
                                    <ion-chip v-if="roadbook.roundtrip" color="secondary" mode="ios">
                                        <ion-label>{{$t('roundtrip')}}</ion-label>
                                    </ion-chip>
                                </ion-label>
                                <ion-buttons v-if="!selectionEnabled" slot="end">
                                    <ion-button :id="'roadbook-'+roadbook.id">
                                        <ion-icon slot="icon-only" :icon="ellipsisVertical"></ion-icon>
                                    </ion-button>
                                </ion-buttons>
                            </ion-item>
                            <ion-card-content @click="isMobile && revealCard(roadbook.id)" @mouseover="revealCard(roadbook.id)" @mouseleave="revealCard(null)" class="ion-no-padding">
                                <ion-icon v-if="roadbook.favorite" :icon="heart" color="tertiary" class="favortie"></ion-icon>
                                <div v-show="!selectionEnabled && revealCardActions == roadbook.id" class="actions-overlay">
                                    <ion-button expand="block" shape="round" @click="roadbookInfoModal.open(roadbook.id)">
                                        <ion-icon slot="start" :icon="informationCircleOutline"></ion-icon>
                                        <ion-label class="bold">{{$t('Details')}}</ion-label>
                                    </ion-button>
                                    <ion-button expand="block" shape="round" @click="editRoadook(roadbook)">
                                        <ion-icon slot="start" :icon="createOutline"></ion-icon>
                                        <ion-label class="bold">{{$t('Edit')}}</ion-label>
                                    </ion-button>
                                </div>
                                <img src="/screenshot.png"/>
                            </ion-card-content>
                        </ion-card>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-col>

        <ion-popover v-for="roadbook of displayedRoadbooks" :trigger="'roadbook-'+roadbook.id" :dismiss-on-select="true">
            <ion-content>
                <ion-list>
                    <ion-item button :detail="false" @click="toggleFavorite(roadbook)">
                        <ion-icon slot="start" :icon="roadbook.favorite ? heartDislike:heart"></ion-icon>
                        {{ $t('Favorite') }}
                    </ion-item>
                    <ion-item button :detail="false" @click="rename(roadbook)">
                        <ion-icon slot="start" :icon="createOutline"></ion-icon>
                        {{ $t('Rename') }}
                    </ion-item>
                    <ion-item button :detail="false" @click="copyRoadbook(roadbook)">
                        <ion-icon slot="start" :icon="copyOutline"></ion-icon>
                        {{ $t('Copy') }}
                    </ion-item>
                    <ion-item-divider color="medium"></ion-item-divider>
                    <ion-item button :detail="false" @click="shareRoadbook(roadbook)">
                        <ion-icon slot="start" :icon="arrowRedoOutline"></ion-icon>
                        {{ $t('Share') }}
                    </ion-item>
                    <ion-item button :detail="false" @click="uploadRoadbook(roadbook)">
                        <ion-icon slot="start" :icon="cloudUpload" color="tertiary"></ion-icon>
                        {{ $t('Add to Tripy device') }}
                    </ion-item>
                    <ion-item-divider color="medium"></ion-item-divider>
                    <ion-item button :detail="false" @click="archiveRoadbook(roadbook)">
                        <ion-icon slot="start" :icon="archive"></ion-icon>
                        {{ $t('Archive') }}
                    </ion-item>
                    <ion-item button :detail="false" @click="deleteRoadbook(roadbook.id)">
                        <ion-icon slot="start" :icon="trashBinOutline" color="danger"></ion-icon>
                        {{ $t('Delete') }}
                    </ion-item>
                </ion-list>
            </ion-content>
        </ion-popover>

        <ion-col sizeLg="12" sizeXl="4">
            <ion-card>
                <ion-card-content>
                    <ion-list>
                        <ion-item button @click="displayCategory('all')">
                            <ion-icon :color="selectedCategory == 'all' ? 'primary':'light'" class="ion-margin-end" :icon="navigateCircle"></ion-icon>
                            <ion-label :color="selectedCategory == 'all' ? 'primary':'light'">{{$t('All')}}</ion-label>
                        </ion-item>
                        <ion-item button @click="displayCategory('lastActivities')">
                            <ion-icon :color="selectedCategory == 'lastActivities' ? 'primary':'light'" class="ion-margin-end" :icon="time"></ion-icon>
                            <ion-label :color="selectedCategory == 'lastActivities' ? 'primary':'light'">{{$t('Last activities')}}</ion-label>
                        </ion-item>
                        <ion-item button @click="displayCategory('favorites')">
                            <ion-icon :color="selectedCategory == 'favorites' ? 'primary':'light'" class="ion-margin-end" :icon="heart"></ion-icon>
                            <ion-label :color="selectedCategory == 'favorites' ? 'primary':'light'">{{$t('Favorite')}}</ion-label>
                            <ion-chip color="primary" outline="true">
                                <ion-label>{{roadbooksStore.favorites.length}}</ion-label>
                            </ion-chip>
                        </ion-item>
                        <ion-item button @click="displayCategory('flash')">
                            <MaterialSymbol :color="selectedCategory == 'flash' ? 'primary':'light'" name="bolt" class="ion-margin-end"></MaterialSymbol>
                            <ion-label :color="selectedCategory == 'flash' ? 'primary':'light'">{{$t('Flash')}}</ion-label>
                            <ion-chip color="primary" outline="true">
                                <ion-label>{{roadbooksStore.flash.length}}</ion-label>
                            </ion-chip>
                        </ion-item>
                        <ion-item button @click="displayCategory('discovery')">
                            <MaterialSymbol :color="selectedCategory == 'discovery' ? 'primary':'light'" name="laps"  class="ion-margin-end"></MaterialSymbol>
                            <ion-label :color="selectedCategory == 'discovery' ? 'primary':'light'">{{$t('Boomerang')}}</ion-label>
                            <ion-chip color="primary" outline="true">
                                <ion-label>{{roadbooksStore.discovery.length}}</ion-label>
                            </ion-chip>
                        </ion-item>
                        <ion-item button  @click="displayCategory('routing')">
                            <MaterialSymbol :color="selectedCategory == 'routing' ? 'primary':'light'" name="stylus_note"  class="ion-margin-end"></MaterialSymbol>
                            <ion-label :color="selectedCategory == 'routing' ? 'primary':'light'">{{$t('Expert')}}</ion-label>
                            <ion-chip color="primary" outline="true">
                                <ion-label>{{roadbooksStore.routing.length}}</ion-label>
                            </ion-chip>
                        </ion-item>
                        <ion-item button  @click="displayCategory('archived')">
                            <MaterialSymbol :color="selectedCategory == 'archived' ? 'primary':'light'" name="inventory_2"  class="ion-margin-end"></MaterialSymbol>
                            <ion-label :color="selectedCategory == 'archived' ? 'primary':'light'">{{$t('Archived')}}</ion-label>
                            <ion-chip color="primary" outline="true">
                                <ion-label>{{roadbooksStore.archived.length}}</ion-label>
                            </ion-chip>
                        </ion-item>
                        <ion-item-divider>
                        </ion-item-divider>
                        <ion-item>
                            <ion-text color="primary">{{ $t('Albums') }}</ion-text>
                            <ion-button slot="end" fill="outline">
                            <ion-icon slot="start" :icon="add"></ion-icon>
                                {{$t('Add')}}
                            </ion-button>
                        </ion-item>
                        <ion-item button>
                            <ion-icon class="ion-margin-end" :icon="bookmark"></ion-icon>
                            <ion-label>Entre copains</ion-label>
                            <ion-chip color="primary" mode="ios" outline="true">
                                <ion-label>12</ion-label>
                            </ion-chip>
                        </ion-item>
                        <ion-item button>
                            <ion-icon class="ion-margin-end" :icon="bookmark"></ion-icon>
                            <ion-label>Club Grenoble</ion-label>
                            <ion-chip color="primary" mode="ios" outline="true">
                                <ion-label>14</ion-label>
                            </ion-chip>
                        </ion-item>
                        <ion-item button>
                            <ion-icon class="ion-margin-end" :icon="bookmark"></ion-icon>
                            <ion-label>Voyages</ion-label>
                            <ion-chip color="primary" mode="ios" outline="true">
                                <ion-label>3</ion-label>
                            </ion-chip>
                        </ion-item>
                    </ion-list>
                </ion-card-content>
            </ion-card>
        </ion-col>
    </ion-row>

    <RoadbookInfoModal ref="roadbookInfoModal"></RoadbookInfoModal>
    <CreateRoadbookModal ref="roadbookModal" @onCreated="roadbookCreated" showTitle></CreateRoadbookModal>
    <TripyUploadModal ref="tripyUpload" :saveGPX="false"></TripyUploadModal>
</template>

<script setup lang="ts">
    import { roadbookAPI } from '@/libs/backend';
    import { add, addCircleOutline, albumsOutline, analyticsOutline, archive, archiveOutline, arrowRedoOutline, bookmark, checkboxOutline, close, cloudUpload, cloudUploadOutline, compass, copyOutline, create, createOutline, ellipsisVertical, heart, heartDislike, image, informationCircleOutline, list, navigateCircle, navigateCircleOutline, pricetagOutline, reloadOutline, removeCircleOutline, squareOutline, time, trashBinOutline } from 'ionicons/icons';
    import { ref, onMounted, inject } from 'vue';
    import { confirmAlert, presentToast } from '@/libs/userExperience'
    import { useI18n } from "vue-i18n";
    import { useRouter } from 'vue-router';
    import { loadingController, alertController } from '@ionic/vue';
    import { confirmDeletion } from '@/libs/userExperience'
    import RoadbookInfoModal from './RoadbookInfoModal.vue';
    import CreateRoadbookModal from './CreateRoadbookModal.vue';
    import TripyUploadModal from './TripyUploadModal.vue';
    import { MaterialSymbol } from '@dbetka/vue-material-symbols';
    import { RoadbooksStore } from '@/stores/RoadbooksStore';
    import { DevicesStore } from '@/stores/DevicesStore';
    import { Vue3Lottie } from 'vue3-lottie';
    import NoResult from '@/components/lottie/Animation - 1731493566959.json'

    // Variables
    const isDesktop=inject('isDesktop')
    const isMobile=inject('isMobile')
    const displayMode = ref('list')
    const displayedRoadbooks:any = ref([])
    const selectedCategory = ref('all')

    const i18n = useI18n();
    const router = useRouter();
    
    const selectionEnabled = ref(false)
    const revealCardActions = ref(null)
    const cardSize = ref(2.4)
    
    var copied = false;
    var selectedRoadbooks:any = ref([]);

    // HTML Element refs
    const roadbookModal = ref()
    const roadbookInfoModal = ref()
    const lottieNoResult = ref()
    const tripyUpload = ref()

    // Stores
    const roadbooksStore = RoadbooksStore();
    const devicesStore = DevicesStore()

    onMounted(()=>{
        displayedRoadbooks.value = roadbooksStore.roadbooks;
        displayCategory('all')
    })

    function toggleCardSize(){
        cardSize.value = 4
        if(cardSize.value == 2.4) cardSize.value = 3
        if(cardSize.value == 3) cardSize.value = 4
        if(cardSize.value == 4) cardSize.value = 2.4
    }
  
    function revealCard(roadbookId:any){
        if(isMobile && revealCardActions.value == roadbookId) revealCardActions.value = null
        else revealCardActions.value = roadbookId;
    }

    async function refresh(){
        await roadbooksStore.fetch();
        displayCategory(selectedCategory.value)
    }

    async function roadbookCreated(roadbook:any){
        let edit = true;
        if(copied){
            refresh(); // display new one anyway
            edit = await confirmAlert({
                header: i18n.t('Edit this copy ?'),
                mode: 'ios',
                buttons: [
                    { text: i18n.t('No'), role: 'cancel' },
                    { text: i18n.t('Yes'), role: 'confirm' },
                ]
            })
        }

        copied = false;
        
        if(edit){
            router.push({path: `/roadbooks/${roadbook.id}/edit/${roadbook.type}`})
        }
    }

    function displayCategory(roadbookCategory:string){
        displayedRoadbooks.value = roadbooksStore.get(roadbookCategory);
        selectedCategory.value = roadbookCategory;
        if(! displayedRoadbooks.value.length){
            lottieNoResult.value.stop();
            lottieNoResult.value.play();
        }
    }

    async function rename(roadbook:any){
        const alert = await alertController.create({
            header: i18n.t('Edit Title'),
            buttons: [ 
                {role:"cancel", text: i18n.t('Discard')},
                {role:"confirm", text: i18n.t('OK')}
            ],
            inputs: [
                {
                    placeholder: i18n.t('Add a title here'),
                    value: roadbook.title,
                    id: 'title-rename',
                },
            ],
        });
        await alert.present();
        // focus field on alert show
        document.getElementById('title-rename')?.focus();

        const {role, data} = await alert.onDidDismiss();
        if(role == 'confirm'){ 
            // update roadbook title
            roadbook.title = data.values[0];
            roadbookAPI.update(roadbook.id, roadbook);
        }
    }

    function shareRoadbook(roadbook:any){
        alert('TODO SHARE')
    }

    function uploadRoadbook(roadbook:any){
        tripyUpload.value.present(roadbook.id)
    }

    async function archiveRoadbook(roadbook:any){
        roadbook.archived = true
        await roadbookAPI.update(roadbook.id, roadbook);
        await refresh();
        presentToast(roadbook.title+' '+ i18n.t('archived'))
    }

    async function deleteRoadbook(roadbookID:string){
        if(await confirmDeletion()){
            roadbookAPI.delete(roadbookID).then(()=> {
                refresh();
            })
        }
    }

    function copyRoadbook(roadbook:any){
        let data = JSON.parse(JSON.stringify(roadbook)); // copy object
        data.title = i18n.t('Copy of') + " " + data.title
        if(data.id) delete data.id
        copied = true;
        roadbookModal.value.openCopy(data)
    }

    function sitchToSelect(){
        selectionEnabled.value = !selectionEnabled.value;
        selectedRoadbooks.value = [];
    }

    function select(e:Event, roadbook:any){
        if(selectionEnabled.value == true){
            e.preventDefault()
            e.stopPropagation()
            let index = selectedRoadbooks.value.indexOf(roadbook.id);
            if(index == -1) selectedRoadbooks.value.push(roadbook.id)
            else{
                selectedRoadbooks.value.splice(index,1)
            }
        }
    }

    function selectAll(){
        selectedRoadbooks.value = []
        for(let roadbook of displayedRoadbooks.value){
            selectedRoadbooks.value.push(roadbook.id)
        }
    }

    function editRoadook(roadbook:any){
        if(selectionEnabled.value == true){

        }
        else{
            router.push({path: `/roadbooks/${roadbook.id}/edit/${roadbook.type}`})
        }
    }

    function isSelected(roadbook:any){
        return (selectedRoadbooks.value.indexOf(roadbook.id) != -1)
    }

    async function tagSelected(){
        let actionLoader = await loadingController.create({
            message: i18n.t('Processing'),
        });
        actionLoader.present()
        for(let roadbookID of selectedRoadbooks.value){
            // await roadbookAPI.delete(roadbookID)
        }
        await refresh();
        actionLoader.dismiss()
    }

    async function archiveSelected(){
        let actionLoader = await loadingController.create({
            message: i18n.t('Processing'),
        });
        actionLoader.present()
        for(let roadbookID of selectedRoadbooks.value){
            await roadbookAPI.update(roadbookID, {'archived': true})
        }
        refresh();
        actionLoader.dismiss()
    }

    async function deleteSelected(){
        let actionLoader = await loadingController.create({
            message: i18n.t('Processing'),
        });
        actionLoader.present()
        for(let roadbookID of selectedRoadbooks.value){
            await roadbookAPI.delete(roadbookID)
        }
        await refresh();
        actionLoader.dismiss()
    }

    async function toggleFavorite(roadbook:any){
        roadbook.favorite = !roadbook.favorite;
        let data = {favorite: roadbook.favorite};
        await roadbookAPI.update(roadbook.id, data);
        await refresh();
    }
</script>

<style scoped>
    ion-card {
        border-radius: 10px;
    }
    .selectable{
        border: 1px solid white;
    }
    .selected{
        border: 1px solid var(--ion-color-tertiary) !important;
    }
    .favortie {
        z-index: 200;
        position: absolute;
        font-size: 50px;
        background-color: var(--ion-component-background);
        bottom: 0px;
        right: 0px;
        border-radius: 6px;
    }
    .actions-overlay {
        z-index: 100;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(150,150,150,0.5);
    }
    .actions-overlay ion-button {
        height: 60px;
        font-size: 20px
    }
    .actions-overlay ion-button:first-child {
        margin-top: 35%;
    }
    ion-item-divider {
    height: 5px !important;
    max-height: 5px !important;
    min-height: 5px !important;
    }

    ion-segment.icon-segment {
        width: 150px;
    }

    .no-result .lottie-animation-container {
        width: 50%;
    }
    .no-result h1 {
        text-decoration-line: underline;
        text-decoration-color: var(--ion-color-primary);
        text-decoration-thickness: 3px;
        
    }
</style>