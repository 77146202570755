<template>
    <ion-page>
        <ion-content :fullscreen="true">
            <ion-row class="div-center">

                <ion-col sizeLg="4" offsetLg="4" sizeMd="6" offsetMd="3" sizeSm="12" offsetSm="0" v-on:keyup.enter="userSignUp">
                    <ion-row>
                        <ion-col size="12" class="ion-text-center">
                            <img class="tripy-white-logo" src="/tripy_white.svg" />
                        </ion-col>
                        <ion-col size="12" class="ion-text-center">
                            <h1 v-if="waitConfirmation">{{$t('Account validation')}}</h1>
                            <h1 v-else>{{$t('Account creation')}}</h1>
                        </ion-col>
                    </ion-row>
                
                    <div v-if="!showForm && !waitConfirmation">
                        <ion-row v-if="!showForm && !waitConfirmation" class="ion-margin-top">
                            <ion-col size="12" class="ion-margin-top ion-text-center">
                                <b>{{ $t('with') }}</b>
                            </ion-col>
                        </ion-row>
                        <OAuthButtons></OAuthButtons>
                        <ion-row>
                            <ion-col size="12" class="ion-margin-top ion-text-center">
                                <b>{{ $t('or') }}</b>
                            </ion-col>
                            <ion-col size="12">
                                <ion-button @click="showForm = true" expand="block" color="primary" size="large">
                                    <b>{{ $t('With my email') }}</b>
                                </ion-button>
                            </ion-col>
                        </ion-row>
                    </div>

                    <div v-if="showForm && !waitConfirmation">
                        <ion-row>
                            <ion-col size="12">
                                <UsernameInput ref="usernameInput" @update:username="(u:any) => username=u"></UsernameInput>
                            </ion-col>
                            <ion-col size="12">
                                <ion-input ref="emailInput" type="email" :placeholder="$t('email')" v-model="email" :error-text="$t('Required field')" class="signup-input">
                                    <ion-icon slot="start" :icon="mail" size="large"></ion-icon>
                                </ion-input>
                            </ion-col>
                        </ion-row>

                        <PasswordForm ref="passwordForm" v-if="showForm" @update:password="(p) => password=p"></PasswordForm>

                        <ion-row>
                            <ion-col size="12" class="ion-margin-top">
                                <ion-button @click="userSignUp" :disabled="loading" expand="block" color="primary" size="large">
                                    <b v-if="!loading">{{ $t('Confirm') }}</b>
                                    <ion-spinner v-if="loading" name="crescent"></ion-spinner>
                                </ion-button>
                            </ion-col>
                        </ion-row>
                    </div>

                    <CheckMailbox v-if="waitConfirmation" :email="email"></CheckMailbox>

                    <ion-button router-link="/login" expand="block" fill="clear" shape="round" class="ion-margin-top">
                        {{$t('Back to Login')}}
                    </ion-button>

                </ion-col>
            </ion-row>
        </ion-content>
    </ion-page>
</template>

<script setup lang="ts">
    import { authAPI } from '@/libs/backend';
    import { showIonInputError, presentToast } from '@/libs/userExperience';
    import { ref, onUpdated } from 'vue';
    import { useI18n } from 'vue-i18n';
    import CheckMailbox from '@/components/CheckMailbox.vue';
    import PasswordForm from '@/components/PasswordForm.vue';
    import OAuthButtons from '@/components/OAuthButtons.vue';
    import UsernameInput from '@/components/UsernameInput.vue';
    import { mail } from 'ionicons/icons';
    
    const username = ref('');
    const email = ref('');
    const password = ref('');
    const loading = ref(false);
    const waitConfirmation = ref(false)
    const showForm = ref(false);
    
    // HTML elements refs
    const usernameInput = ref();
    const emailInput = ref();
    const passwordForm = ref();

    const i18n = useI18n();

    onUpdated(()=>{
        showForm.value = false;
        loading.value = false;
    })


    async function userSignUp(){
        let error = false;
        error = usernameInput.value.hasError() || error;
        error = showIonInputError(emailInput, !email.value) || error;

        error = await usernameInput.value.verifyUsername() || error
        error = passwordForm.value.hasError() || error;

        if(error)
            return
    
        let signupData = {
            email:email.value, 
            password:password.value,
            username:username.value
        }
        authAPI.signup(signupData).then((res)=>{
            if(!res.detail){ // sign up error will throw a detail
                presentToast(i18n.t('Successfully registered'))

                showForm.value = false
                waitConfirmation.value = true
            }
            else if (res.detail == 'USER_EMAIL_ALREADY_EXISTS') presentToast(i18n.t('USER_EMAIL_ALREADY_EXISTS'), 'warning')   
            else if(res.detail == 'REGISTER_USER_ALREADY_EXISTS') presentToast(i18n.t('REGISTER_USER_ALREADY_EXISTS'), 'warning');
            else if(res.detail == 'USERNAME_ALREADY_EXISTS') presentToast(i18n.t('USERNAME_ALREADY_EXISTS'), 'warning');
        },
        (error)=>{
            console.log('signup THEN ERROR')
        });
    }

</script>

<style scoped>
    @import '@/theme/login.css';
</style>