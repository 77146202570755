<template>
        <ion-button id="open-video" fill="clear" shape="round" color="medium" size="medium" :class="props.class+' ion-margin-start'">
            <ion-icon slot="start" :icon="playCircleOutline"></ion-icon>
            {{$t('Tuto')}}
        </ion-button>
        <ion-modal ref="tutoModal" trigger="open-video">
            <ion-content >
                <iframe class="div-center" width="100%" height="315" src="https://www.youtube.com/embed/uRFbaQi6U_4?si=VydKIzgo4HyQ6zXt" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </ion-content>
            <ion-footer>
                <ion-toolbar>
                    <ion-button v-on:click="tutoModal.$el.dismiss()" expand="block" color="light" size="large">
                        {{$t('Close')}}
                    </ion-button>
                </ion-toolbar>
            </ion-footer>
        </ion-modal>
</template>

<script setup lang="ts">
    import { ref } from 'vue'
    import { close, playCircleOutline } from 'ionicons/icons';

    const props = defineProps({
        class: {
            type: String,
            default: '',
        },
    });

    const tutoModal = ref();
</script>