<template>
    <ion-page>
        <ion-content :fullscreen="true">
            <ion-row class="div-center">
                <ion-col sizeLg="4" offsetLg="4" sizeMd="6" offsetMd="3" sizeSm="12" offsetSm="0">
                    <ion-row>
                        <ion-col size="12" class="ion-text-center">
                            <ion-spinner name="crescent" color="primary"></ion-spinner>
                            <img class="tripy-white-logo" src="/tripy_white.svg" />
                        </ion-col>
                    </ion-row>
                </ion-col>
            </ion-row>

        </ion-content>
    </ion-page>
</template>

<script setup lang="ts">
    import { onMounted } from 'vue';
    import { authAPI } from '@/libs/backend'
    import { useRoute } from 'vue-router';
    
    const route = useRoute();
    
    onMounted(()=>{
        OAuthLogin();
    })
    
    function OAuthLogin(){
        let params = {}
        let provider:string|undefined = route.query.provider?.toString()
        let code:string|undefined = route.query.code?.toString()
        let state:string|undefined = route.query.state?.toString()
        let error:string|undefined = route.query.error?.toString()

        if(code){
            params = {
                code:code,
                state:state,
            }
        }
        else if(error){
            params = {
                state:state,
                error:error,
            }
        }
        else{
            close()
        }
        
        if(provider=='facebook')    FacebookLogin(params)
        else if(provider=='google') GoogleLogin(params)
        else if(provider=='apple')  AppleLogin(params)
        else close()
    }

    function FacebookLogin(params:any){
        authAPI.FacebookOAuth2Login(params).then((response)=>{
            close(response)
        },
        (error)=>{
            close()
        })
    }

    function GoogleLogin(params:any){
        let scope:string|undefined = route.query.scope?.toString()
        let authuser:string|undefined = route.query.authuser?.toString()
        let prompt:string|undefined = route.query.prompt?.toString()
        let googleExtraParams:any = {
            scope: scope,
            authuser: authuser,
            prompt: prompt
        }
        params = { ...params, ...googleExtraParams }

        authAPI.GoogleOAuth2Login(params).then((response)=>{
            close(response)
        },
        (error)=>{
            close()
        })
    }

    function AppleLogin(params:any){
        authAPI.GoogleOAuth2Login(params).then((response)=>{
                close(response)
            },
            (error)=>{
                close()
            })
    }

    function close(response=null){
        window.opener.postMessage(response);
        window.close()
    }
</script>

<style scoped>
    @import '@/theme/login.css';

    ion-spinner {
        transform: scale(8);
        z-index: 9999;
        left: 50%;
        top: 50%;
        margin-top: -15px;   /* = -height / 2   */
        margin-left: -15px;  /* = -width / 2    */
        position: fixed;     /* Fixed is better */
    }
</style>