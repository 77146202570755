
<template>
    <span class="ion-justify-content-center">
        <ion-text class="document-field ion-float-left" v-if="document && ! fieldEdition" @click="editField" :color="document[props.fieldName] ? 'light':'warning'">
                {{ document[props.fieldName] || $t(props.placeholder) }}
        </ion-text>
        <ion-buttons v-if="document && ! fieldEdition">
            <ion-button @click="editField">
                <ion-icon slot="icon-only" :icon="createOutline" color="primary"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-input v-if="document && fieldEdition" type="text" v-model="fieldvalue" fill="outline"
            ref="fieldInput" color="primary" class="ion-no-margin placeholder-padding ion-float-left"  
            @keydown.escape="fieldEdition = false" @keydown.enter="updateField()">
            <ion-button slot="end" @click="updateField()" fill="clear" aria-label="ok">
                <ion-icon slot="icon-only" :icon="checkboxOutline" shape="rounded" aria-hidden="true"></ion-icon>
            </ion-button>
        </ion-input>
        <ion-spinner v-if="updating" name="circular" class="ion-margin-start" color="tertiary"></ion-spinner>
    </span>
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import { checkboxOutline, createOutline } from 'ionicons/icons';
    import { focusInput } from '@/libs/userExperience';
    import { BackendAPI } from '@/libs/backend';

    const fieldEdition = ref(false);
    const fieldInput = ref()
    const fieldvalue = ref()
    const fieldLength = ref("22ch")
    const updating = ref(false)

    const props = defineProps({
        documentAPI: {
            type: BackendAPI,
            required: true,
        },
        document: {
            type: Object,
            required: true,
        },
        documentId: {
            type: String,
            required: true,
        },
        fieldName: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            default: 'Enter Text'
        },
        autoUpdate: {
            type: Boolean,
            default: true,
        },
    });

    const emit = defineEmits<{
        (e: 'onUpdated', data: string): void,
    }>()

    function calculateFieldSize(){
        fieldvalue.value = props.document[props.fieldName] || '';
        fieldLength.value = `${fieldvalue.value.length}ch`;
    }

    function editField(){
        calculateFieldSize();
        fieldEdition.value = true;
        focusInput(fieldInput);
    }

    function updateField(){
        if(props.document[props.fieldName] == fieldvalue.value){ // no need to update
            fieldEdition.value = false;
            return
        }

        updating.value = true;

        if(props.autoUpdate){
            let data:any = {};
            data[props.fieldName] = fieldvalue.value;
            props.documentAPI.update(props.documentId, data)
            .then(()=>{
                props.document[props.fieldName] = fieldvalue.value;
                emit('onUpdated', fieldvalue.value)
            })
            .finally(()=>{
                updating.value = false;
                fieldEdition.value = false;
                calculateFieldSize();
            });
        }
        else {
            emit('onUpdated', fieldvalue.value)
            updating.value = false;
            fieldEdition.value = false;
            calculateFieldSize();
        }

    }

</script>

<style scoped>
    ion-text.document-field{
        cursor: pointer;
        font-size: 20px;
        max-width: 100%;
        padding-top:10px
    }

    ion-input{
        min-width: 22ch;
        width: v-bind(fieldLength);
        color: white;
        font-size: 18px;
    }
</style>

