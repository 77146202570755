<template>
    {{ location.poi?.name }}, {{ location.address.freeformAddress }}
</template>

<script setup lang="ts">
    const props = defineProps({
        location: {
            type: Object,
            required: true,
        },
    });

    defineExpose({
        latlng
    })

    function latlng(){
        return [parseFloat(props.location.position.lat), parseFloat(props.location.position.lon)]
    }
</script>
