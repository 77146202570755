<template>
    <ion-page>
        <ion-content :fullscreen="true">
            <ion-row class="div-center">
                <ion-col sizeLg="4" offsetLg="4" sizeMd="6" offsetMd="3" sizeSm="12" offsetSm="0">
                    <ion-row>
                        <ion-col size="12" class="ion-text-center">
                            <img class="tripy-white-logo {" src="/tripy_white.svg" />
                        </ion-col>
                        <ion-col size="12" class="ion-text-center">
                            <h1>{{ $t('Welcome') }}</h1>
                        </ion-col>
                        <ion-col size="12" class="ion-text-center">
                            <h3>{{$t('define_username')}}</h3>
                        </ion-col>
                        <ion-col size="12" class="ion-text-center">
                        </ion-col>
                        <ion-col size="12">
                            <UsernameInput ref="usernameInput" @update:username="(u:any) => username=u"></UsernameInput>
                        </ion-col>
                        <ion-col v-if="usernameInput" size="12" class="ion-margin-top">
                            <ion-button @click="setUsername" expand="block" color="tertiary" size="large" :disabled="usernameInput.hasError() ? 'true':'false'">
                                <ion-spinner v-if="loading" name="crescent"></ion-spinner>
                                <b>{{$t('Send')}}</b>
                            </ion-button>
                        </ion-col>
                        <ion-item lines="none" color="petrol">
                            <MaterialSymbol name="info" size="3em" color="primary" class="ion-margin-end"></MaterialSymbol>
                            <h4><ion-text color="light">{{$t('define_username_details')}}</ion-text></h4>
                        </ion-item>
                    </ion-row>
                </ion-col>
            </ion-row>
        </ion-content>
    </ion-page>
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import { MaterialSymbol } from '@dbetka/vue-material-symbols';
    import UsernameInput from '@/components/UsernameInput.vue';
    import { userAPI } from '@/libs/backend';
    import { AuthStore } from '@/stores/AuthStore';
    import { useRouter } from 'vue-router';

    const usernameInput = ref();
    const username = ref('');
    const loading = ref(false);
    const authStore = AuthStore();
    const router = useRouter()

    async function setUsername(){
        let userData:any = authStore.userData()
        userData.username = username.value;
        loading.value = true;
        userAPI.updateInfos(userData).then((data)=>{
            loading.value = false;
            if(data.username == username.value){
                authStore.setUserData(data)
                router.push('/')
            }
        })
    }
</script>

<style scoped>
    @import '@/theme/login.css';
</style>