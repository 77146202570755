<template>
    <ion-page>
        <ion-header>
            <ion-toolbar v-if-mobile>
                <BackNavButton></BackNavButton>
                <ion-title>{{$t('RoadBooks')}}</ion-title>
            </ion-toolbar>
            <ion-toolbar v-if-desktop>
                <BackNavButton></BackNavButton>
                <ion-title>{{$t('RoadBooks')}}</ion-title>
                <ion-button shape="rounded" size="large" slot="end" class="ion-margin-end" fill="clear" color="light">
                    <ion-icon slot="start" :icon="arrowDownCircleOutline"></ion-icon>
                    {{$t('Import')}}
                </ion-button>
                <ion-button v-show="roadbooksStore.populated()" slot="end" @click="roadbookModal.open()" shape="round" size="large" class="bold">
                    <ion-icon ref="createButton" slot="start" :icon="addCircleOutline"></ion-icon>
                    {{$t('Create')}}
                </ion-button>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <PageLoader :loading="loading" :spinner="true"></PageLoader>

            <div v-if="roadbooksStore.populated()">
                <ion-toolbar>
                    <ion-segment v-model="visibleSection" @ionChange="">
                        <ion-segment-button layout="icon-start" value="gallery">
                            <ion-icon :icon="grid"></ion-icon>
                            <ion-label>{{$t('Gallery')}}</ion-label>
                        </ion-segment-button>
                        <ion-segment-button layout="icon-start" value="map">
                            <ion-icon :icon="map"></ion-icon>
                            <ion-label>{{$t('Map')}}</ion-label>
                        </ion-segment-button>
                        <ion-segment-button layout="icon-start" value="search">
                            <ion-icon :icon="search"></ion-icon>
                            <ion-label>{{$t('Search')}}</ion-label>
                        </ion-segment-button>
                    </ion-segment>
                </ion-toolbar>

                <div v-if="visibleSection == 'gallery'">
                    <RoadbooksGallery></RoadbooksGallery>
                </div>

                <div v-if="visibleSection == 'map'">
                    <RoadbooksMap></RoadbooksMap>
                </div>

                <div v-if="visibleSection == 'search'">
                    <ion-toolbar>
                        <ion-item>
                            <RoadbookFilter ref="roadbookFilter" :roadbooks="roadbooksStore.roadbooks" v-model:filtered-roadbooks="roadbooksStore.searchResult"
                                btn-slot="start" btn-class="ion-margin-end"></RoadbookFilter>
                            <ion-searchbar :placeholder="$t('Search')" color="light"></ion-searchbar>
                        </ion-item>
                    </ion-toolbar>
                    <div v-if="roadbooksStore.searchResult.length" class="div-center text-center">
                        <div>
                            <ion-text>
                                <h4>{{ $t("No result")}}</h4>
                            </ion-text>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else-if="!loading" class="div-center text-center">
                <div>
                    <ion-text>
                        <h4>{{ $t("Your list is empty")}}</h4>
                    </ion-text>
                </div>
                <ion-button @click="roadbookModal.open()" shape="round" size="large" class="bold">
                    {{ $t("Create a Roadbook")}}
                </ion-button>
            </div>
            
        </ion-content>

        <CreateRoadbookModal ref="roadbookModal" @onCreated="roadbookCreated" showTitle></CreateRoadbookModal>
    </ion-page>
</template>

<script setup lang="ts">
    import { addCircleOutline, arrowDownCircleOutline, grid, map, search } from 'ionicons/icons';
    import { ref, onMounted, onUpdated } from 'vue';
    import { refreshData } from '@/libs/userExperience'
    import RoadbooksGallery from '@/components/RoadbooksGallery.vue';
    import CreateRoadbookModal from '@/components/CreateRoadbookModal.vue';
    import RoadbookFilter from '@/components/RoadbookFilter.vue';
    import RoadbooksMap from '@/components/RoadbooksMap.vue';
    import BackNavButton from '@/components/BackNavButton.vue';
    import PageLoader from '@/components/PageLoader.vue';
    import { useRouter } from 'vue-router';
    import { createAnimation } from '@ionic/vue';
    import { RoadbooksStore } from '@/stores/RoadbooksStore';

    // Variables
    const loading = ref(false)
    const visibleSection = ref('gallery')

    // Vue
    const router = useRouter();
    
    // HTML Element refs
    const roadbookModal = ref()
    const createButton = ref()
    const roadbookFilter = ref()

    // Store
    const roadbooksStore = RoadbooksStore();

    onMounted(async ()=>{
        fetchRoadbooks();

        // Create button animation
        createAnimation()
          .addElement(createButton.value.$el)
          .fill('none')
          .duration(5000)
          .iterations(Infinity)
          .keyframes([
            { offset: 0, transform: 'scale(1)', opacity: '1' },
            { offset: 0.1, transform: 'scale(1.2)', opacity: '0.3' },
            { offset: 0.2, transform: 'scale(1)', opacity: '1' },
            { offset: 1, transform: 'scale(1)', opacity: '1' },
          ]).play();
    });

    onUpdated(async ()=>{
        refreshData(fetchRoadbooks)
    });
  
    async function fetchRoadbooks(){
        loading.value = true;
        await roadbooksStore.init();
        loading.value = false;
    }

    async function roadbookCreated(roadbook:any){
        router.push({path: `/roadbooks/${roadbook.id}/edit/${roadbook.type}`})
    }
</script>

<style scoped>

</style>