/* 
    Sources used from https://github.com/miladd3/vue-simple-password-meter
*/

export function checkStrength(pass: string): any{
    const score = scorePassword(pass)
    const strength = nameScore(score)
    return { score, strength }
}

export function isCommonPassword(password: string): boolean {
    const commonPasswords = [
        "123456",
        "azerty",
        "qwerty",
        "password",
        "111111",
        "Abc123",
        "123456789",
        "12345678",
        "123123",
        "1234567890",
        "12345",
        "1234567",
        "qwertyuiop",
        "qwerty123",
        "1q2w3e",
        "password1",
        "123321",
        "Iloveyou",
        "12345"
      ]
  return commonPasswords.includes(password);
}

export function nameScore(score: number): string {
    switch (score) {
      case 0:
        return 'risky'
      case 1:
        return 'guessable'
      case 2:
        return 'weak'
      case 3:
        return 'safe'
      case 4:
        return 'secure'
      default:
        return ''
    }
}

export function scorePassword(pass:string): number {
  let score = 0
  let length = 0
  let specialChar = 0
  let caseMix = 0
  let numCharMix = 0

  const specialCharRegex = /[^A-Za-z0-9]/g
  const lowercaseRegex = /(.*[a-z].*)/g
  const uppercaseRegex = /(.*[A-Z].*)/g
  const numberRegex = /(.*[0-9].*)/g
  const repeatCharRegex = /(\w)(\1+\1+\1+\1+)/g

  const hasSpecialChar = specialCharRegex.test(pass)
  const hasLowerCase = lowercaseRegex.test(pass)
  const hasUpperCase = uppercaseRegex.test(pass)
  const hasNumber = numberRegex.test(pass)
  const hasRepeatChars = repeatCharRegex.test(pass)

  if (pass.length > 4) {
    if (isCommonPassword(pass)) {
      return 0
    }

    if ((hasLowerCase || hasUpperCase) && hasNumber) {
      numCharMix = 1
    }

    if (hasUpperCase && hasLowerCase) {
      caseMix = 1
    }

    if ((hasLowerCase || hasUpperCase || hasNumber) && hasSpecialChar) {
      specialChar = 1
    }

    if (pass.length > 8) {
      length = 1
    }

    /* Tripy : not a security criteria */
    // if (pass.length > 12 && !hasRepeatChars) {
    //   length = 2
    // }
    // if (pass.length > 20 && !hasRepeatChars) {
    //   length = 3
    // }

    score = length + specialChar + caseMix + numCharMix

    if (score > 4) {
      score = 4
    }
  }

  return score
}

export default scorePassword
