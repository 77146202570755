// DEMO here : https://vdcrea.gitlab.io/vue-leaflet/#ltilelayer

export const tileProviders:any = [
    {
      name: 'OpenStreetMap',
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      options: {
        maxZoom: 19,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      }
    },
    {
      name: 'TomTomRasterMap',
      url: 'https://{s}.api.tomtom.com/map/1/tile/basic/main/{z}/{x}/{y}.png?key=VIPxjGwfgz2f7b5QLmBHgM0OVdwEb0uo&tileSize=512',
      options: {
        maxZoom: 23,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">TomTom Map</a>'
      }
    },
    {
      name: 'TomTomRasterMap Proxy',
      url: 'https://rallye2.tripy.be/tiles/tomtom/{z}/{x}/{y}.png',
      options: {
        maxZoom: 23,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">TomTom Map</a>'
      }
    },
    // {
    //   name: 'OpenStreetMap Grey',
    //   url: 'http://{s}.tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png',
    //   options: {
    //     maxZoom: 18,
    //     attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    //   }
    // },
    {
      name: 'OpenTopoMap',
      url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
      options: {
        maxZoom: 17,
        attribution: 'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
      }
    },
    // {
    //   name: 'GIScience Research Group',
    //   url: 'https://korona.geog.uni-heidelberg.de/tiles/roads/x={x}&y={y}&z={z}',
    //   options: {
    //     maxZoom: 20,
    //     attribution: 'Imagery from <a href="http://giscience.uni-hd.de/">GIScience Research Group @ University of Heidelberg</a> &mdash; Map data &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    //   }
    // },
    {
      name: 'Stamen Design Toner',
      url: 'https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}{r}.png',
      options: {
        attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        subdomains: 'abcd',
        minZoom: 0,
        maxZoom: 20
      }
    },
    {
      name: 'ArcGIS World Topo Map',
      url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
      options: {
        attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community'
      }
    },
    // {
    //   name: 'ArcGIS Shaded Relief',
    //   url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Shaded_Relief/MapServer/tile/{z}/{y}/{x}',
    //   options: {
    //     attribution: 'Tiles &copy; Esri &mdash; Source: Esri',
    //     maxZoom: 13
    //   }
    // },
    // {
    //   name: 'Geoportail France',
    //   url: 'https://wxs.ign.fr/{apikey}/geoportail/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&STYLE={style}&TILEMATRIXSET=PM&FORMAT={format}&LAYER=GEOGRAPHICALGRIDSYSTEMS.MAPS&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}',
    //   options: {
    //     attribution: '<a target="_blank" href="https://www.geoportail.gouv.fr/">Geoportail France</a>',
    //     bounds: [[-75, -180], [81, 180]],
    //     minZoom: 2,
    //     maxZoom: 18,
    //     apikey: 'choisirgeoportail',
    //     format: 'image/jpeg',
    //     style: 'normal'
    //   }
    // }
  ]

  export const defaultMapLayer = tileProviders[0].url;