<template>
        <ion-content id="dashboard" :fullscreen="true">
            <img id="menu-logo" src="/tripymap_the_route.svg" alt="tripymap the route" class="ion-padding"/>
            <ion-row>
                <ion-col size="12">
                    <ion-item lines="none">
                        <TutoButton slot="start"></TutoButton>
                        <ion-buttons v-if="isLoggedIn()" slot="end">
                            <ion-button id="open-account-menu" size="large">
                                {{ user.username }}
                                <MaterialSymbol slot="end" class="ion-margin-start" name="account_circle"></MaterialSymbol>
                            </ion-button>
                        </ion-buttons>
                        <!-- <ion-button v-else slot="end" fill="outline" size="medium">
                            <MaterialSymbol slot="start" class="ion-margin-end" name="account_circle"></MaterialSymbol>
                            {{$t('Login')}}
                        </ion-button> -->
                    </ion-item>
                </ion-col>

                <!-- <ion-col size="12" class="ion-text-center">
                    <ion-text>
                        <h2 class="strong">{{ $t("Start your") }}<span><i><b>TRIP</b></i></span></h2>
                    </ion-text>
                </ion-col> -->

                <ion-col size="12" class="ion-no-padding">
                    <ion-card id="open-new-trip" color="petrol" button fill="outline">
                        <ion-card-content class="ion-text-center ion-text-uppercase">
                            <ion-text color="light">
                                <h1><b>{{$t('Create a trip')}}</b></h1>
                            </ion-text>
                        </ion-card-content>
                    </ion-card>
                </ion-col>

                <ion-col size="12" class="ion-no-padding">
                    <ion-card id="open-new-trip" color="petrol" button fill="outline" router-link="/community">
                        <ion-card-content class="ion-text-center ion-text-uppercase">
                            <ion-text color="light">
                                <h1><b>{{$t('Explore')}}</b></h1>
                            </ion-text>
                        </ion-card-content>
                    </ion-card>
                </ion-col>

                <!-- <ion-col v-if="!isLoggedIn()" size="12" class="ion-no-padding">
                    <ion-card id="open-new-trip" color="petrol" button fill="outline">
                        <ion-card-content class="ion-text-center ion-text-uppercase">
                            <ion-text color="light">
                                <h1><b>{{$t('Login')}}</b></h1>
                            </ion-text>
                        </ion-card-content>
                    </ion-card>
                </ion-col> -->

                <ion-col v-if="!isLoggedIn()"  size="12" class="ion-padding ion-text-center">
                    <ion-button fill="outline" size="medium" expand="block" router-link="/login">
                        <MaterialSymbol slot="start" class="ion-margin-end" name="account_circle"></MaterialSymbol>
                        {{$t('Login')}}
                    </ion-button>
                    <ion-text color="primary" >
                        create an account for more features
                    </ion-text>
                </ion-col>

                <ion-col v-if="isLoggedIn() && lastEditedRoadbooks.length"  size="12" class="ion-no-padding">
                    <ion-card id="open-new-trip" fill="outline">
                        <ion-item button color="anthracite" router-link="/roadbooks">
                            <ion-text color="light" class="ion-text-uppercase">
                                <h1><b>{{$t('My trips')}}</b></h1>
                            </ion-text>
                        </ion-item>
                        <ion-card-content class="ion-no-padding">

                                            <!-- <ion-card-title>{{ $t("Last activities") }}</ion-card-title> -->
                                    <ion-list>
                                        <ion-item v-for="roadbook of lastEditedRoadbooks" button color="petrol" :router-link="'/roadbooks/'+roadbook.id+'/edit/'+roadbook.type">
                                            <ion-text color="light">{{ roadbook.title }}</ion-text>
                                        </ion-item>
                                    </ion-list>
                        </ion-card-content>
                    </ion-card>
                </ion-col>




                <ion-col size="12">
                    <!-- <ion-item button class="menu-button" lines="none"  router-link="/roadbooks">
                        <ion-label color="primary">{{$t('My Roadbooks')}}</ion-label>
                        <MaterialSymbol slot="end" name="menu" color="primary"></MaterialSymbol>
                    </ion-item>
                    <ion-item button class="menu-button" lines="none"  router-link="/community">
                        <ion-label color="primary">{{$t('Community')}}</ion-label>
                        <MaterialSymbol slot="end" name="public" color="primary"></MaterialSymbol>
                    </ion-item> -->
                    <!-- <ion-item button class="menu-button" lines="none"  router-link="/devices">
                        <ion-label>{{$t('Devices')}}</ion-label>
                        <MaterialSymbol slot="end" name="assistant_navigation"></MaterialSymbol>
                    </ion-item>
                    <ion-item button class="menu-button" lines="none"  router-link="/parameters">
                        <ion-label>{{$t('Settings')}}</ion-label>
                        <MaterialSymbol slot="end" name="settings"></MaterialSymbol>
                    </ion-item>
                    <ion-item button class="menu-button" lines="none"  router-link="/user/account">
                        <ion-label>{{$t('Account')}}</ion-label>
                        <MaterialSymbol slot="end" name="account_circle"></MaterialSymbol>
                    </ion-item> -->
                </ion-col>

            </ion-row>

            <ion-modal ref="newTripModal" mode="ios" trigger="open-new-trip" :initial-breakpoint="0.75" :breakpoints="[0.75]">
                <ion-content>
                    <ion-item>
                        <ion-buttons slot="end">
                            <ion-button @click="newTripModal.$el.dismiss()" slot="end" size="large" color="light">
                                <ion-icon slot="icon-only" :icon="close"></ion-icon>
                            </ion-button>
                        </ion-buttons>
                    </ion-item>
                    <ion-card color="light" button @click="createRoadbook('flash')">
                        <ion-card-header>
                            <ion-item color="none" button mode="md" lines="none" class="trip-button ion-margin-bottom">
                                <MaterialSymbol name="bolt"></MaterialSymbol>
                                <ion-label slot="end">{{$t('FLASH TRIP')}}</ion-label>
                            </ion-item>
                        </ion-card-header>
                        <ion-card-content>
                            {{ $t('Route, upload, ride') }}
                        </ion-card-content>
                    </ion-card>
                    <ion-card color="light" button @click="createRoadbook('discovery')">
                        <ion-card-header>
                            <ion-item color="none" button mode="md" lines="none" class="trip-button ion-margin-bottom">
                                <MaterialSymbol name="laps" grade="bold"></MaterialSymbol>
                                <ion-label slot="end">{{$t('BOOMERANG')}}</ion-label>
                            </ion-item>
                        </ion-card-header>
                        <ion-card-content>
                            {{ $t('Discover a new route around you') }}
                        </ion-card-content>
                    </ion-card>
                    <ion-card color="light" button @click="createRoadbook('routing')">
                        <ion-card-header>
                            <ion-item color="none" button mode="md" lines="none" class="trip-button ion-margin-bottom">
                                <MaterialSymbol name="stylus_note"></MaterialSymbol>
                                <ion-label slot="end">{{$t('EXPERT')}}</ion-label>
                            </ion-item>
                        </ion-card-header>
                        <ion-card-content>
                            {{ $t('Edit your route with roadbook requirements') }}
                        </ion-card-content>
                    </ion-card>
                </ion-content>
            </ion-modal>


            <ion-modal ref="accountModal" mode="ios" trigger="open-account-menu" :initial-breakpoint="0.5" :breakpoints="[0.5]">
                <ion-content>
                    <ion-item>
                        <ion-buttons slot="end">
                            <ion-button @click="accountModal.$el.dismiss()" slot="end" size="large" color="light">
                                <ion-icon slot="icon-only" :icon="close"></ion-icon>
                            </ion-button>
                        </ion-buttons>
                    </ion-item>
                    <ion-row>
                        <ion-col size="12">
                        <ion-item button class="menu-button" lines="none"  @click="navigateOption('/devices')">
                            <ion-label>{{$t('Devices')}}</ion-label>
                            <MaterialSymbol slot="end" name="assistant_navigation"></MaterialSymbol>
                        </ion-item>
                        <ion-item button class="menu-button" lines="none"  @click="navigateOption('/parameters')">
                            <ion-label>{{$t('Settings')}}</ion-label>
                            <MaterialSymbol slot="end" name="settings"></MaterialSymbol>
                        </ion-item>
                        <ion-item button class="menu-button" lines="none"  @click="navigateOption('/user/account')">
                            <ion-label>{{$t('Account')}}</ion-label>
                            <MaterialSymbol slot="end" name="account_circle"></MaterialSymbol>
                        </ion-item>
                        </ion-col>
                    </ion-row>
                </ion-content>
            </ion-modal>
        </ion-content>
</template>

<script setup lang="ts">
    import { onMounted, onUpdated, Ref, ref } from 'vue';
    import { earth, hardwareChipOutline, menu, cogOutline, personCircleOutline, createOutline, chevronForward, close } from 'ionicons/icons';
    import TutoButton from '@/components/TutoButton.vue';
    import { useRouter } from 'vue-router';
    import { MaterialSymbol } from '@dbetka/vue-material-symbols';
    import { roadbookAPI } from '@/libs/backend';
    import { isLoggedIn } from '@/services/authService';
    import { AuthStore } from '@/stores/AuthStore';

    const router = useRouter();
    const authStore = AuthStore();

    const newTripModal = ref();
    const accountModal = ref();
    
    const lastEditedRoadbooks:Ref<any[]> = ref([])
    const user:any = ref({})

    onMounted(()=>{
        updateDashboard()
    });

    onUpdated(()=>{
        updateDashboard()
    })

    function updateDashboard(){
        user.value = {}
        lastEditedRoadbooks.value = []
        if(isLoggedIn()) {
            user.value = authStore.userData()
            fetchRoadbooks()
        }
    }

    async function fetchRoadbooks(){
        lastEditedRoadbooks.value = await roadbookAPI.all({limit:3, sorting:['-updated_date']});
    }

    function createRoadbook(type:string){
        newTripModal.value.$el.dismiss();
        router.push({path: `/roadbooks/new/edit/${type}`})
    }

    function navigateOption(route:string){
        accountModal.value.$el.dismiss()
        router.push({path: route})
    }

</script>


<style scoped>
    .trip-button {
        border-radius: 10px;
        border: 2px var(--ion-color-light) solid;
        font-size: 25px;
    }

    .menu-button {
        border-radius: 10px;
        --background: none;
    }
</style>