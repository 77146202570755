<template>
    <span v-for="line of location.displayLines">
        {{ line }}<br>
    </span>
</template>

<script setup lang="ts">
    const props = defineProps({
        location: {
            type: Object,
            required: true,
        },
    });

    defineExpose({
        latlng
    })

    function latlng(){
        return [props.location.latitude, props.location.longitude]
    }
</script>
