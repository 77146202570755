<template>
    <ion-page>
        <ion-content :fullscreen="true">
            <div class="ion-text-center">
                <ion-row>
                    <ion-col offset="4" size="4">
                        <ion-row>
                            <ion-col size="12">
                                <ion-input type="text" placeholder="Search text" v-model="inputText" @keyup.enter="search">
                                    <ion-buttons slot="end">
                                        <ion-button @click="search" color="primary" fill="outline" shape="round">
                                            Search
                                        </ion-button>
                                    </ion-buttons>
                                </ion-input>
                            </ion-col>
                        </ion-row>
                    </ion-col>
                </ion-row>


                <div :style="'height:300px; width:100%'">
                    <l-map ref="map" :zoom="5" :center="[46.66181748307193, 7.799474973236367]" 
                        :useGlobalLeaflet="false" :options="{zoomControl: false,  zoomSnap:0.1, attributionControl: false }"
                    >
                
                        <l-tile-layer :url="defaultMapLayer" layer-type="base" name="TripyMap3"></l-tile-layer>
                        
                        <!-- SEARCH RESULT LAYER -->
                        <l-marker v-if="selectedPlace" :lat-lng="selectedPlace"> 
                            <l-icon :iconSize="[32, 32]" :iconAnchor="[16, 32]" :popupAnchor="[3,-25]" :options="{className:''}"> 
                                <PinIcon color="#3880ff"></PinIcon>
                            </l-icon>
                        </l-marker>
                    </l-map>
                </div>

                <ion-accordion-group :multiple="true" :value="['fourth']">
                    <ion-accordion value="zero">
                    </ion-accordion>
                    <ion-accordion value="first">
                        <ion-item slot="header">
                            <ion-label>OPEN SOURCE (gratuits)</ion-label>
                        </ion-item>
                        <ion-row slot="content">
                            <ion-col size="4">
                                <ion-title color="warning">PHOTON</ion-title>
                                <ion-progress-bar v-if="photonLoading" type="indeterminate" color="primary"></ion-progress-bar>
                                <ion-item v-for="location, index in photonRes" button @click="showLatLng(photonHostedRefs[index].latlng())">
                                    <ion-label><PhotonLocation :ref="photonHostedRefs.set" :location="location"></PhotonLocation></ion-label>
                                </ion-item>
                            </ion-col>

                            <ion-col size="4">
                                <ion-title color="warning">NOMINATIM</ion-title>
                                <ion-progress-bar v-if="nominatimLoading" type="indeterminate" color="primary"></ion-progress-bar>
                                <ion-item v-for="location, index in nominatimRes" button @click="showLatLng(nominatimRefs[index].latlng())">
                                    <ion-label><NominatimLocation :ref="nominatimRefs.set" :location="location"></NominatimLocation></ion-label>
                                </ion-item>
                            </ion-col>
                            <ion-col size="4">
                                <ion-title color="warning">PELIAS</ion-title>
                                <ion-progress-bar v-if="peliasLoading" type="indeterminate" color="primary"></ion-progress-bar>
                                <ion-item v-for="location, index in peliasRes" button @click="showLatLng(peliasRefs[index].latlng())">
                                    <ion-label><PeliasLocation :ref="peliasRefs.set" :location="location"></PeliasLocation></ion-label>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                        <ion-row slot="content">
                            <ion-col size="4">
                                <ion-title color="warning">PHOTON <ion-text color="light"><i>HOSTED</i></ion-text></ion-title>
                                <ion-progress-bar v-if="photonHostedLoading" type="indeterminate" color="primary"></ion-progress-bar>
                                <ion-item v-for="location, index in photonHostedRes" button @click="showLatLng(photonHostedRefs[index].latlng())">
                                    <ion-label><PhotonLocation :ref="photonHostedRefs.set" :location="location"></PhotonLocation></ion-label>
                                </ion-item>
                            </ion-col>
                            <ion-col size="4">
                                <ion-title color="warning">NOMINATIM <ion-text color="light"><i>HOSTED</i></ion-text></ion-title>
                                <ion-progress-bar v-if="nominatimHostedLoading" type="indeterminate" color="primary"></ion-progress-bar>
                                <ion-item v-for="location, index in nominatimHostedRes" button @click="showLatLng(nominatimHostedRefs[index].latlng())">
                                    <ion-label><NominatimLocation :ref="nominatimHostedRefs.set" :location="location"></NominatimLocation></ion-label>
                                </ion-item>
                            </ion-col>
                            <ion-col size="4">
                                <ion-title color="warning">PELIAS <ion-text color="light"><i>HOSTED</i></ion-text></ion-title>
                                <ion-progress-bar v-if="peliasHostedLoading" type="indeterminate" color="primary"></ion-progress-bar>
                                <ion-item v-for="location, index in peliasHostedRes" button @click="showLatLng(peliasHostedRefs[index].latlng())">
                                    <ion-label><PeliasLocation :ref="peliasHostedRefs.set" :location="location"></PeliasLocation></ion-label>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                    </ion-accordion>
                    <ion-accordion value="third">
                        <ion-item slot="header">
                            <ion-label>PAYANTS</ion-label>
                        </ion-item>
                        <ion-row slot="content">
                            <ion-col size="4">
                                <ion-title color="warning">MAPQUEST</ion-title>
                                <ion-progress-bar v-if="mapquestLoading" type="indeterminate" color="primary"></ion-progress-bar>
                                <ion-item v-for="location, index in mapquestRes" button @click="showLatLng(mapquestRefs[index].latlng())">
                                    <ion-label><MapQuestLocation :ref="mapquestRefs.set" :location="location"></MapQuestLocation> </ion-label>
                                </ion-item>
                            </ion-col>
                            <ion-col size="4">
                                <ion-title color="warning">GEOCODE EARTH</ion-title>
                                <ion-progress-bar v-if="geocodeEarthLoading" type="indeterminate" color="primary"></ion-progress-bar>
                                <ion-item v-for="location, index in geocodeEarthRes" button @click="showLatLng(geocodeEarthRefs[index].latlng())">
                                    <ion-label><GeocodeHearthLocation :ref="geocodeEarthRefs.set" :location="location"></GeocodeHearthLocation> </ion-label>
                                </ion-item>
                            </ion-col>
                            <ion-col size="4">
                                <ion-title color="warning">MAPBOX</ion-title>
                                <ion-progress-bar v-if="mapboxLoading" type="indeterminate" color="primary"></ion-progress-bar>
                                <ion-item v-for="location, index in mapboxRes" button @click="showLatLng(mapboxRefs[index].latlng())">
                                    <ion-label><MapboxLocation :ref="mapboxRefs.set" :location="location"></MapboxLocation> </ion-label>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                    </ion-accordion>
                    <ion-accordion value="fourth">
                        <ion-item slot="header">
                            <ion-label color="primary">FINALISTES</ion-label>
                        </ion-item>
                        <ion-row slot="content">
                            <ion-col size="4">
                                <ion-title color="warning">HERE</ion-title>
                                <ion-progress-bar v-if="hereLoading" type="indeterminate" color="primary"></ion-progress-bar>
                                <ion-item v-for="location, index in hereRes" button @click="showLatLng(hereRefs[index].latlng())">
                                    <ion-label><HereLocation :ref="hereRefs.set" :location="location"></HereLocation> </ion-label>
                                </ion-item>
                            </ion-col>
                            <ion-col size="4">
                                <ion-title color="warning">TOMTOM</ion-title>
                                <ion-progress-bar v-if="tomtomLoading" type="indeterminate" color="primary"></ion-progress-bar>
                                <ion-item v-for="location, index in tomtomRes" button @click="showLatLng(tomtomRefs[index].latlng())">
                                    <ion-label><TomTomLocation :ref="tomtomRefs.set" :location="location"></TomTomLocation> </ion-label>
                                </ion-item>
                            </ion-col>
                            <ion-col size="4">
                                <ion-title color="warning">Google Place Search</ion-title>
                                <ion-progress-bar v-if="googlePlaceSearchLoading" type="indeterminate" color="primary"></ion-progress-bar>
                                <ion-item v-for="location, index in googlePlaceSearchRes" button @click="showLatLng(googlePlaceSearchRefs[index].latlng())">
                                    <ion-label><GooglePlaceLocation :ref="googlePlaceSearchRefs.set" :location="location"></GooglePlaceLocation> </ion-label>
                                </ion-item>
                            </ion-col>
                            <ion-col size="4">
                                <ion-title color="warning">APPLE MAP SEARCH</ion-title>
                                <ion-progress-bar v-if="appleMapSearchLoading" type="indeterminate" color="primary"></ion-progress-bar>
                                <ion-item v-for="location, index in appleMapSearchRes" button @click="showLatLng(appleMapSearchRefs[index].latlng())">
                                    <ion-label><AppleSearchLocation :ref="appleMapSearchRefs.set" :location="location"></AppleSearchLocation> </ion-label>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                    </ion-accordion>

                </ion-accordion-group>
            </div>
            


        </ion-content>
    </ion-page>
</template>

<script setup lang="ts">
    import GeocodeHearthLocation from '@/components/geocoder/GeocodeEarthLocation.vue';
    import HereLocation from '@/components/geocoder/HereLocation.vue';
    import MapboxLocation from '@/components/geocoder/MapboxLocation.vue';
    import MapQuestLocation from '@/components/geocoder/MapQuestLocation.vue';
    import NominatimLocation from '@/components/geocoder/NominatimLocation.vue';
    import PeliasLocation from '@/components/geocoder/PeliasLocation.vue';
    import PhotonLocation from '@/components/geocoder/PhotonLocation.vue';
    import TomTomLocation from '@/components/geocoder/TomTomLocation.vue';
    import PinIcon from '@/components/icons/PinIcon.vue';
    import { locationAPI } from '@/libs/backend';
    import { MapStore } from '@/stores/mapStore';
    import { useTemplateRefsList } from '@vueuse/core';
    import { ref } from 'vue';
    import "leaflet"; // to use L object
    import "leaflet/dist/leaflet.css";
    import { LMap, LTileLayer, LMarker, LIcon } from "@vue-leaflet/vue-leaflet";
    import { defaultMapLayer } from '@/libs/tileProviders'
    import GooglePlaceLocation from '@/components/geocoder/GooglePlaceLocation.vue';
    import AppleSearchLocation from '@/components/geocoder/AppleSearchLocation.vue';


    const map = ref()
    const inputText = ref('')
    // Photon
    const photonRes:any = ref([])
    const photonLoading = ref(false)
    var photonRefs = useTemplateRefsList<HTMLDivElement>()
    const photonHostedRes:any = ref([])
    const photonHostedLoading = ref(false)
    var photonHostedRefs = useTemplateRefsList<HTMLDivElement>()
    // Nominatim
    const nominatimRes:any = ref([])
    const nominatimLoading = ref(false)
    var nominatimRefs = useTemplateRefsList<HTMLDivElement>()
    const nominatimHostedRes:any = ref([])
    const nominatimHostedLoading = ref(false)
    var nominatimHostedRefs = useTemplateRefsList<HTMLDivElement>()
    // Pelias
    const peliasRes:any = ref([])
    const peliasLoading = ref(false)
    var peliasRefs = useTemplateRefsList<HTMLDivElement>()
    const peliasHostedRes:any = ref([])
    const peliasHostedLoading = ref(false)
    var peliasHostedRefs = useTemplateRefsList<HTMLDivElement>()
    // MapQuest
    const mapquestRes:any = ref([])
    const mapquestLoading = ref(false)
    var mapquestRefs = useTemplateRefsList<HTMLDivElement>()
    // TomTom
    const tomtomRes:any = ref([])
    const tomtomLoading = ref(false)
    var tomtomRefs = useTemplateRefsList<HTMLDivElement>()
    // MapBox
    const mapboxRes:any = ref([])
    const mapboxLoading = ref(false)
    var mapboxRefs = useTemplateRefsList<HTMLDivElement>()
    // Here
    const hereRes:any = ref([])
    const hereLoading = ref(false)
    var hereRefs = useTemplateRefsList<HTMLDivElement>()
    // GeocodeHearth
    const geocodeEarthRes:any = ref([])
    const geocodeEarthLoading = ref(false)
    var geocodeEarthRefs = useTemplateRefsList<HTMLDivElement>()
    // Google Places Search
    const googlePlaceSearchRes:any = ref([])
    const googlePlaceSearchLoading = ref(false)
    var googlePlaceSearchRefs = useTemplateRefsList<HTMLDivElement>()
    // Apple Map Search
    const appleMapSearchRes:any = ref([])
    const appleMapSearchLoading = ref(false)
    var appleMapSearchRefs = useTemplateRefsList<HTMLDivElement>()


    const RES_LIMIT = 4

    const selectedPlace:any = ref(undefined)

    function showLatLng(latlng:Array<number>){
        console.log(latlng)
        if(latlng){
            selectedPlace.value = latlng
            fitPoints()
        }
    }

    function fitPoints(){
        let points = [selectedPlace.value]
        let fitBounds:any = []
        points.forEach((latlng:any) => {
            let b = latlng.reduce(function(result:any, item:any, index:number, array:any[]) {
                result['lat'] = array[0];
                result['lng'] = array[1];
                return result;
            }, {})
            fitBounds.push(b)
        });

        map.value.leafletObject.fitBounds(fitBounds, {padding: [80,80]}) // Add second param {maxZoom: 10} to set zoom
    }

    function search(e:Event){
        if(inputText.value.length < 3) return

        nominatimLoading.value = true
        nominatimRes.value = []
        nominatimRefs = useTemplateRefsList<HTMLDivElement>()
        locationAPI.searchGeocoder('nominatim', {q: inputText.value, limit:5, format:'json'}).then((response)=>{
            nominatimLoading.value = false
            nominatimRes.value = response
        })

        nominatimHostedLoading.value = true
        nominatimHostedRes.value = []
        nominatimHostedRefs = useTemplateRefsList<HTMLDivElement>()
        locationAPI.searchGeocoder('nominatim_hosted', {q: inputText.value, limit:RES_LIMIT}).then((response)=>{
            nominatimHostedLoading.value = false
            nominatimHostedRes.value = response
        })

        // photonLoading.value = true
        // photonRes.value = []
        // photonRefs = useTemplateRefsList<HTMLDivElement>()
        // locationAPI.searchGeocoder('photon', {q: inputText.value, limit:RES_LIMIT}).then((response)=>{
        //     photonLoading.value = false
        //     photonRes.value = response.features
        // })

        photonHostedLoading.value = true
        photonHostedRes.value = []
        photonHostedRefs = useTemplateRefsList<HTMLDivElement>()
        locationAPI.searchGeocoder('photon_hosted', {q: inputText.value, limit:RES_LIMIT}).then((response)=>{
            photonHostedLoading.value = false
            photonHostedRes.value = response.features
        })

        peliasLoading.value = true
        peliasRes.value = []
        peliasRefs = useTemplateRefsList<HTMLDivElement>()
        locationAPI.searchGeocoder('pelias', {api_key: 'ge-a7b366435470cb4b', text: inputText.value, size:RES_LIMIT}).then((response)=>{
            peliasLoading.value = false
            peliasRes.value = response.features
        })

        // peliasHostedLoading.value = true
        // peliasHostedRes.value = []
        // peliasHostedRefs = useTemplateRefsList<HTMLDivElement>()
        // locationAPI.searchGeocoder('pelias_hosted', {text: inputText.value, size:RES_LIMIT}).then((response)=>{
        //     peliasHostedLoading.value = false
        //     console.log(response)
        // })

        // DOC: https://developer.mapquest.com/documentation/api/geocoding/address/get.html
        // DOC: https://developer.mapquest.com/documentation/search-api/v4/swagger/
        mapquestLoading.value = true
        mapquestRes.value = []
        mapquestRefs = useTemplateRefsList<HTMLDivElement>()
        locationAPI.searchGeocoder('mapquest', {q: inputText.value, key:'LriUlPIAWDMuPZSs0DcVibjJrTx1pnCr', limit:RES_LIMIT, location: '5.933886365298625,43.1237407297359', collection:'address,adminArea,airport,category,franchise,poi' }).then((response)=>{
            mapquestLoading.value = false
            mapquestRes.value = response.results
        })

        // DOC: https://developer.tomtom.com/search-api/api-explorer
        tomtomLoading.value = true
        tomtomRes.value = []
        tomtomRefs = useTemplateRefsList<HTMLDivElement>()
        // lat: 43.1237407297359, lon: 5.933886365298625, minFuzzyLevel:1, maxFuzzyLevel:4
        locationAPI.searchGeocoder('tomtom', {q: inputText.value, limit:RES_LIMIT, lat: 43.1237407297359, lon: 5.933886365298625 , key:'Z3VN1jZloGvqF2B4TENx9VcjRyWxZRGi'}).then((response)=>{
            tomtomLoading.value = false
            tomtomRes.value = response.results
        })

        // DOC: https://docs.mapbox.com/api/search/search-box/#search-request
        mapboxLoading.value = true
        mapboxRes.value = []
        mapboxRefs = useTemplateRefsList<HTMLDivElement>()
        locationAPI.searchGeocoder('mapbox', {q: inputText.value, limit:RES_LIMIT, access_token:"pk.eyJ1IjoidGF0YXlveW9oIiwiYSI6ImNsOGN2MjIyMTAyaGkzbnM0NHlocnphc2QifQ.QqG6McqBynwaJp_u71XZ4A"}).then((response)=>{
            mapboxLoading.value = false
            mapboxRes.value = response.features
        })

        // DOC: https://www.here.com/docs/bundle/geocoding-and-search-api-developer-guide/page/topics/quick-start.html
        hereLoading.value = true
        hereRes.value = []
        hereRefs = useTemplateRefsList<HTMLDivElement>()
        locationAPI.searchGeocoder('here', {q: inputText.value, at: '43.1237407297359,5.933886365298625', limit:RES_LIMIT, apikey:'X0cD79EXWeva27UaXoFVXkfRDss-IovhvXYQiq0pbmE'}).then((response)=>{
            hereLoading.value = false
            hereRes.value = response.items
        })

        // DOC: https://geocode.earth/docs/forward/search/
        geocodeEarthLoading.value = true
        geocodeEarthRes.value = []
        geocodeEarthRefs = useTemplateRefsList<HTMLDivElement>()
        locationAPI.searchGeocoder('geocodeearth', {text: inputText.value, limit:RES_LIMIT, api_key:'ge-a7b366435470cb4b'}).then((response)=>{
            geocodeEarthLoading.value = false
            geocodeEarthRes.value = response.features
        })

        // DOC: https://geocode.earth/docs/forward/search/
        googlePlaceSearchLoading.value = true
        googlePlaceSearchRes.value = []
        googlePlaceSearchRefs = useTemplateRefsList<HTMLDivElement>()
        locationAPI.searchGeocoder('googleplacesearch', {textQuery: inputText.value, pageSize:RES_LIMIT, api_key:'AIzaSyB5TikTgYulpGmqELCQxGB8x8PEXS2xOfw'}).then((response)=>{
            googlePlaceSearchLoading.value = false
            googlePlaceSearchRes.value = response
        })

        // DOC : https://developer.apple.com/maps/try-maps-server-api/
        appleMapSearchLoading.value = true
        appleMapSearchRes.value = []
        appleMapSearchRefs = useTemplateRefsList<HTMLDivElement>()
        locationAPI.searchGeocoder('applesearch', {q: inputText.value, userLocation: '43.1237407297359,5.933886365298625', api_key:'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJtYXBzYXBpIiwidGlkIjoiWkJTQUJHNFRNOSIsImFwcGlkIjoiVGVzdCBUb2tlbiIsIml0aSI6ZmFsc2UsImlydCI6ZmFsc2UsImlhdCI6MTcyNzc5Njg4NywiZXhwIjoxNzI3Nzk4Njg3fQ.ALnihCqE7dbiAB0FDRbpmNLlaRZnvzlge8ipTrT1GPmTi7eoqln3i27xRnhQ9nKexmITVDR0Jax61nkb1l0Lbg'}).then((response)=>{
            appleMapSearchLoading.value = false
            appleMapSearchRes.value = response.results
            console.log(response)
        })
        
    }
</script>

<style scoped>
@import '@/theme/login.css';
</style>