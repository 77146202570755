<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <!-- <ion-buttons slot="start" color="primary">
                    <ion-menu-toggle>
                        <ion-button fill="clear" shape="round" color="light" size="large">
                        <ion-icon slot="icon-only" :icon="menu"></ion-icon>
                        </ion-button>
                    </ion-menu-toggle>
                </ion-buttons> -->
                <BackNavButton></BackNavButton>
                <ion-title>{{ $t("Devices") }}</ion-title>
                <ion-button v-if="isDesktop && devicesStore.devices.length" slot="end" @click="createDeviceModal.open()" shape="round" size="large" class="bold">
                    <ion-icon ref="createButton" slot="start" :icon="addCircleOutline"></ion-icon>
                    {{$t('Add')}}
                </ion-button>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <ion-button v-if-mobile @click="createDeviceModal.open()" expand="full" size="large" class="bold">
                    <ion-icon ref="createButton" slot="start" :icon="addCircleOutline"></ion-icon>
                    {{$t('Add')}}
            </ion-button>
            
            <ion-row>
                <ion-col size="12" sizeLg="12" sizeXl="8">
                    <ion-card v-if="devicesStore.devices.length" v-for="device in devicesStore.devices">
                        <ion-grid>
                        <ion-row>
                            <ion-col sizeLg='6' size="12">
                                <ion-chip color="primary" class="ion-float-start ion-margin-end">
                                    <ion-text>{{device.model}}</ion-text>
                                </ion-chip>
                                <InlineDocumentFieldEdit :documentAPI="deviceAPI" :document="device" :documentId="device.serial_number" fieldName="label" placeholder="Set a label">{{device.text}}</InlineDocumentFieldEdit>
                            </ion-col>
                            <ion-col sizeLg='3' size="12">
                                <p>
                                    <ion-text color="light">{{ $t('Serial number') }}</ion-text>
                                </p>
                                <ion-text color="primary">
                                    <h4>{{device.serial_number}}
                                        <ion-chip v-if="device.verified" color="success" mode="ios" outline="true">
                                            <ion-icon :icon="shieldCheckmarkOutline"></ion-icon>
                                            <ion-label>{{$t('Verified')}}</ion-label>
                                        </ion-chip>
                                    </h4>
                                </ion-text>
                            </ion-col>
                            <ion-col sizeLg='3' size="12" class="ion-text-end">
                                <ion-button v-if="!device.verified" @click="verify(device.serial_number)" color="warning" fill="outline" class="ion-padding-end">
                                    <ion-icon slot="start" :icon="shieldCheckmarkOutline"></ion-icon>
                                    <ion-label>{{$t('Verify')}}</ion-label>
                                </ion-button>
                                <ion-button @click="remove(device.serial_number)" color="danger" fill="outline">
                                    <ion-label>{{$t('Remove')}}</ion-label>
                                </ion-button>
                            </ion-col>
                        </ion-row>
                        </ion-grid>

                    </ion-card>
                    <div v-else class="div-center text-center">
                        <div>
                            <ion-text>
                                <h4>{{ $t("No Tripy GPS registred")}}</h4>
                            </ion-text>
                        </div>
                        <ion-button v-if-desktop @click="createDeviceModal.open()" shape="round" size="large" class="bold">
                            {{ $t("Add")}}
                        </ion-button>
                    </div>
                </ion-col>
                <ion-col size="12" sizeLg="12" sizeXl="4">
                    <ion-card button class="shop-gps" href="https://www.tripy.eu" target="_blank">
                        <ion-card-content>
                            <img src="/buy-tripy.png">
                            <div class="div-center ion-text-center">
                                <ion-card>
                                    <ion-card-header>
                                        <ion-card-title><ion-text color="primary" class="bold"><h1><b>{{$t('Buy a Tripy GPS')}}</b></h1></ion-text></ion-card-title>
                                        <ion-card-subtitle>{{$t('Access the online store')}}</ion-card-subtitle>
                                    </ion-card-header>
                                </ion-card>
                            </div>
                        </ion-card-content>
                    </ion-card>
                </ion-col>
            </ion-row>

        </ion-content>
        <ion-footer v-if-mobile>
            <ion-toolbar color="primary">
                <ion-button href="https://tripy.be" target="_blank" expand="block" fill="clear" shape="round">
                    <ion-icon slot="start" color="dark" size="large" :icon="cartOutline"></ion-icon>
                    <ion-text color="dark" class="bold"><h3>{{$t('Buy a Tripy GPS')}}</h3></ion-text>
                </ion-button>
            </ion-toolbar>
        </ion-footer>

        <CreateDeviceModal ref="createDeviceModal" @created="fetchDevices" ></CreateDeviceModal>
    </ion-page>
</template>

<script setup lang="ts">
    import { ref, onMounted, onUpdated, inject } from 'vue'
    import { refreshData } from '@/libs/userExperience'
    import { addCircleOutline, cartOutline, shieldCheckmarkOutline } from 'ionicons/icons';
    import { deviceAPI } from '@/libs/backend';
    import CreateDeviceModal from '@/components/CreateDeviceModal.vue';
    import InlineDocumentFieldEdit from '@/components/InlineDocumentFieldEdit.vue';
    import { confirmDeletion } from '@/libs/userExperience'
    import BackNavButton from '@/components/BackNavButton.vue';
    import { DevicesStore } from '@/stores/DevicesStore';

    const isDesktop = inject('isDesktop')
    const createDeviceModal = ref()

    // Stores
    const devicesStore = DevicesStore()

    onUpdated(()=>{
        refreshData(fetchDevices)
    });

    onMounted(()=>{
        devicesStore.init()
    });

    async function fetchDevices(){
        devicesStore.refresh()
    }

    async function remove(serialNumber:string) {
        if(await confirmDeletion()){
            await deviceAPI.delete(serialNumber);
            fetchDevices()
        }
    }

    function verify(serialNumber:string){
        deviceAPI.validate(serialNumber).then(()=>{
            fetchDevices();
        });
    }
</script>

<style scoped>
    .shop-gps {
        max-height: 450px;
    }
    .shop-gps:hover{
        /* border: 1px solid var(--ion-color-primary); */
        background: var(--ion-color-light);
    }
    .shop-gps:hover ion-card-subtitle{
        color: var(--ion-color-light);
    }
    .div-center {
        top : 120px
    }
</style>