<template>
    <ion-row>
        <ion-col size="4">
            <ion-button  expand="block" color="light" @click="googleOAuth2">
                <ion-icon :icon="logoGoogle" size="large"></ion-icon>
                <ion-label class="ion-margin-start" v-if-desktop>Google</ion-label>
            </ion-button>
        </ion-col>
        <ion-col size="4">
            <ion-button  expand="block" color="light" @click="facebookOAuth2">
                <ion-icon :icon="logoFacebook" size="large"></ion-icon>
                <ion-label class="ion-margin-start" v-if-desktop>Facebook</ion-label>
            </ion-button>
        </ion-col>
        <ion-col size="4">
            <ion-button  expand="block" color="light" @click="appleOAuth2">
                <ion-icon :icon="logoApple" size="large"></ion-icon>
                <ion-label class="ion-margin-start" v-if-desktop>Apple ID</ion-label>
            </ion-button>
        </ion-col>
    </ion-row>
</template>

<script setup lang="ts">
    import { logoApple, logoFacebook, logoGoogle } from 'ionicons/icons';
    import { authAPI } from '@/libs/backend'
    import { presentToast } from '@/libs/userExperience';
    import { useI18n } from 'vue-i18n';
    import { postLoginAction } from '@/services/authService';

    const i18n = useI18n();


    function googleOAuth2(){
        // Note : this plugin can be used as extra login for Google OAuth2
        //        https://github.com/devbaji/vue3-google-login

        authAPI.GoogleOAuth2().then((response:any)=>{
            genericOAuth2(response, 700, 600, 'googleOAuthWindow')
        });
    }

    function facebookOAuth2(){
        authAPI.FacebookOAuth2().then((response:any)=>{
            genericOAuth2(response, 600, 600, 'facebookOAuthWindow')
        });
    }

    function appleOAuth2(){
        authAPI.AppleOAuth2().then((response:any)=>{
            genericOAuth2(response, 600, 600, 'appleOAuthWindow')
        });
    }

    function genericOAuth2(response:any, height:number, width:number, windowTargetName:string){
        if(response.authorization_url){
            let left = screen.width / 2 - width/2
            let top = screen.height /2 - height/2
            let oauthWindow = window.open(response.authorization_url, windowTargetName, `height=${height},width=${width},toolbar=no,menubar=no,scrollbars=no,resizable=no,location=no,directories=no,status=no,top=${top}px,left=${left}px`)

            if(!oauthWindow){
                // popup blocker
                presentToast(i18n.t('Your browser seems to not allow popups. Please allow popups to continue.'), 'danger');
            }
            else{
                // popup callback
                window.onmessage = function (e) {
                    if (e.data && e.data.access_token) {
                        postLoginAction(e.data.access_token)
                    } 
                    else if (e.data && e.data.success == false && e.data.detail) {
                        if(e.data.detail == 'access_denied'){
                            // Auhtentication canceled by the user himself, no message to display
                        }
                    }
                    else {
                        presentToast(i18n.t('An error occured'), 'danger');
                        if(oauthWindow) oauthWindow.close()
                    }
                };
            }
        }
    }
</script>