<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <BackNavButton></BackNavButton>
                <ion-title>{{ $t("Account") }}</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content :fullscreen="true">
            <ion-progress-bar v-if="loading" type="indeterminate" color="primary"></ion-progress-bar>
            <div v-if="user">
                <ion-list>
                    <ion-item>
                        <ion-label>{{ $t('Username') }}</ion-label>
                        <ion-label color="primary">{{user.username}}</ion-label>
                    </ion-item>
                    <ion-item>
                        <ion-label>{{ $t('Email') }}</ion-label>
                        <ion-label color="primary">{{user.email}}</ion-label>
                    </ion-item>
                    <ion-item>
                        <ion-label>{{ $t('Password') }}</ion-label>
                        <ion-button id="change-password-modal" fill="clear" >
                            <ion-icon :icon="key" class="ion-margin-end"></ion-icon>
                            {{ $t('Change password') }}
                        </ion-button>
                    </ion-item>
                    <ion-item>
                        <ion-label>{{ $t('Used login methods') }}</ion-label>
                        <span v-for="account of user.accounts">
                            <ion-chip v-if="account == 'google'" color="primary" mode="ios" outline="true">
                                <ion-icon :icon="logoGoogle"></ion-icon>
                                <ion-text>Google</ion-text>
                            </ion-chip>
                            <ion-chip v-if="account == 'facebook'" color="primary" mode="ios" outline="true">
                                <ion-icon :icon="logoFacebook"></ion-icon>
                                <ion-text>Facebook</ion-text>
                            </ion-chip>
                            <ion-chip v-if="account == 'appleid'" color="primary" mode="ios" outline="true">
                                <ion-icon :icon="logoApple"></ion-icon>
                                <ion-text>AppleID</ion-text>
                            </ion-chip>

                        </span>
                    </ion-item>
                </ion-list>
                <ion-button @click="logout()" color="warning" fill="clear" expand="full">
                    <ion-icon slot="icon-only" :icon="powerOutline" class="ion-margin-end"></ion-icon>
                    {{ $t('Logout') }}
                </ion-button>
                <ion-button id="delete-account-alert" color="danger" fill="clear" expand="full">
                    {{ $t('Delete account') }}
                </ion-button>

                
                <ion-modal ref="changePasswordModal" trigger="change-password-modal" mode="ios" class="change-password-modal">
                    <ion-content>
                        <!-- this input is used for browsers to suggest to register the new password with account name -->
                        <ion-input v-show="false" type="text" name="login" :value="user.email"></ion-input>

                        <PasswordForm ref="passwordForm" @update:password="(p) => password=p"></PasswordForm>
                    </ion-content>
                    <ion-footer>
                        <ion-toolbar>
                            <ion-button @click="changePassword"  expand="block" color="tertiary" size="large">
                                <ion-spinner v-if="changePasswordloading" name="crescent"></ion-spinner>
                                <b v-else>{{$t('Send')}}</b>
                            </ion-button>
                        </ion-toolbar>
                    </ion-footer>
                </ion-modal>

                <ion-alert
                    trigger="delete-account-alert"
                    :header="i18n.t('Enter your username to confirm account deletion')"
                    :buttons="alertButtons"
                    :inputs="alertInputs"
                ></ion-alert>
            </div>
        </ion-content>
    </ion-page>
</template>

<script setup lang="ts">
    import { ref, onMounted } from 'vue'
    import { key, logoApple, logoFacebook, logoGoogle, powerOutline, trash } from 'ionicons/icons';
    import { presentToast, refreshData } from '@/libs/userExperience'
    import BackNavButton from '@/components/BackNavButton.vue';
    import { userAPI } from '@/libs/backend';
    import PasswordForm from '@/components/PasswordForm.vue';
    import { useI18n } from 'vue-i18n';
    import { logout } from '@/services/authService'
    
    const i18n = useI18n()
    
    const password = ref('');
    const loading = ref(false);
    const changePasswordloading = ref(false);
    const user:any = ref(undefined);

    const passwordForm = ref();
    const changePasswordModal = ref();

    const alertInputs = [
    {
      placeholder: i18n.t('username'),
    },
  ];

    const alertButtons = [
    {
      text: i18n.t('Cancel'),
      role: 'cancel',
    },
    {
      text: i18n.t('OK'),
      role: 'confirm',
      handler: (data:any) => {
        if(data[0] == user.value.username){
            deleteAccount()
        }
      },
    },
  ];

    

    onMounted(()=>{
        fetchAccount()
    });

    async function fetchAccount(){
        loading.value = true;
        user.value = await userAPI.userInfos()
        loading.value = false;
    }

    async function deleteAccount(){
        await userAPI.deleteAccount()
        logout()
    }

    function changePassword(){
        if(password.value && ! passwordForm.value.hasError()) {
            changePasswordloading.value = true;
            user.value.password = password.value
            userAPI.updateInfos(user.value).then((response)=>{
                changePasswordloading.value = false;
                presentToast(i18n.t('Password succeful changed'))
                changePasswordModal.value.$el.dismiss()
            })
        }
    }
</script>

<style scoped>
    ion-modal.change-password-modal {
        --height: 300px
    }
</style>