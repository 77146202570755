<template>
    <ion-text>
        {{ placeName() }}
    </ion-text>
</template>

<script setup lang="ts">
    const props = defineProps({
        location: {
            type: Object,
            required: true,
        },
    });

    defineExpose({
        latlng
    })

    function placeName(){
        let loc = props.location.properties;
        let place = [loc.name,  loc.city, postcode(),  loc.county,  loc.country]
        place = place.filter((p)=>p)
        return place.join(', ')
    }

    function postcode(){
        if(props.location.properties.postcode && props.location.properties.postcode.includes(';')){
            return props.location.properties.postcode.split(';')[0]
        }
        return props.location.properties.postcode;
    }

    function latlng(){
        return [props.location.geometry.coordinates[1], props.location.geometry.coordinates[0]]
    }
</script>

<style scoped>
</style>
