<template>
    <ion-text>
        {{ placeName() }}
    </ion-text>
</template>

<script setup lang="ts">
    const props = defineProps({
        location: {
            type: Object,
            required: true,
        },
    });

    defineExpose({
        latlng
    })

    function placeName(){
        let geometry = props.location.geometry;
        let bbox = props.location.bbox;
        let loc = props.location.properties;

        let place = [loc.name,  loc.locality, loc.postalcode, loc.region,  loc.country]
        place = place.filter((p)=>p)
        return place.join(', ')
    }
    
    function latlng(){
        return [props.location.geometry.coordinates[1], props.location.geometry.coordinates[0]]
    }

</script>

<style scoped>
</style>
