<template>
    <div>
        <ion-modal ref="searchSheet" show-backdrop="false" 	backdrop-breakpoint="1" backdrop-dismiss="false" :can-dismiss="true"
            :initial-breakpoint="0.5" :breakpoints="isDesktop ? [0.35, 0.5, 0.75, 0.924]:[0.5, 0.924]" @didPresent="focusSearchBar" @keyup.escape="dismiss"
            class="left-modal auto-height" @ionBreakpointDidChange="changeHeight">
            <ion-header>
                <ion-toolbar>
                    <ion-segment v-model="sheetSegment">
                        <ion-segment-button value="search" layout="icon-start">
                            <ion-icon :icon="searchOutline"></ion-icon>
                            <ion-label>{{$t('Search')}}</ion-label>
                        </ion-segment-button>
                        <ion-segment-button value="favorites" layout="icon-start">
                            <ion-icon :icon="star"></ion-icon>
                            <ion-label>{{$t('Favorite')}}</ion-label>
                        </ion-segment-button>
                    </ion-segment>
                    <ion-button slot="end" @click="dismiss" size="large" fill="clear" color="light" shape="round">
                        <ion-icon slot="icon-only" :icon="close"></ion-icon>
                    </ion-button>
                </ion-toolbar>

            </ion-header>
            
            <ion-content v-if="sheetSegment == 'search'" scrollY="true" scrollEvents="true" forceOverscroll="true">
                <ion-searchbar :value="searchValue" ref="searchBar" @click="" :placeholder="$t('Search a place')"
                    :debounce="300" @ionInput="search($event)" color="medium" :clear-icon="backspace"> 
                </ion-searchbar>
                <ion-progress-bar v-if="loading" type="indeterminate" color="primary"></ion-progress-bar>
                <ion-item v-if="query && !mapStore.searchResults.length" lines="none">
                    <ion-label>{{ $t("No place match") }}</ion-label>
                </ion-item>
                <!-- workaround to scroll Y -->
                <!-- TODO : prevent smartphone touch scroll event from Modal itself (peut seulement agrandir par le header) -->
                <div :style="'height:'+searchHeight+'px;max-height:'+searchHeight+'px;'" class="search-content">
                    <ion-card v-for="result of mapStore.searchResults" button @click="focusPlace(result)">
                        <ion-card-header class="ion-no-padding">
                            <ion-text color="light" class="ion-padding-top ion-padding-start"><NominatimLocation :location="result"></NominatimLocation></ion-text>
                        </ion-card-header>
                        <ion-card-content class="ion-no-padding align-end">
                            <ion-buttons>
                                <ion-chip v-if="result.category" color="primary" mode="ios" outline="true">
                                    <ion-label>{{ result.category }}</ion-label>
                                </ion-chip>
                                <ion-text color="primary" class="">{{$t('Show on map')}}
                                </ion-text>
                                <ion-button @click="">
                                    <ion-icon slot="icon-only" :icon="informationCircle" color="tertiary"></ion-icon>
                                </ion-button>
                                <ion-button @click="setStart(result, $event)">
                                    <ion-icon slot="icon-only" src="/start-circle.svg"></ion-icon>
                                </ion-button>
                                <ion-button v-if="roadbookStore.isRoutable()" @click="addWaypoint(result, $event)">
                                    <ion-icon slot="icon-only" src="/waypoint.svg"></ion-icon>
                                </ion-button>
                                <ion-button v-if="!roadbookStore.isRoundTrip() && roadbookStore.value.map_waypoints.length >= 1" @click="setDestination(result, $event)">
                                    <ion-icon slot="icon-only" src="/stop.svg"></ion-icon>
                                </ion-button>
                            </ion-buttons>
                        </ion-card-content>
                    </ion-card>
                </div>
            </ion-content>

            <ion-content v-if="sheetSegment == 'favorites'" scrollY="true" scrollEvents="true" forceOverscroll="true">
                <ion-text v-if="!favoritePlaces.places.length">
                    <h4 class="ion-margin">{{$t('No favorite saved for now')}}</h4>
                </ion-text>
                <ion-list v-if="favoritePlaces.places.length">
                    <ion-item v-for="favorite of favoritePlaces.places" button>
                        <ion-label>{{favorite.label}}</ion-label>
                        <ion-label>{{favorite.address}}</ion-label>
                    </ion-item>
                </ion-list>
            </ion-content>
        </ion-modal>

        <ion-fab vertical="bottom" :horizontal="isDesktop ? 'start':'start'" class="search-fab" style="margin-left:-20px;margin-bottom:20px">
            <ion-button @click="open()" shape="round" color="anthracite" size="large" class="search-btn ion-padding">
                <ion-icon :icon="searchSharp" :slot="isDesktop ? 'start':'icon-only'" color="light" size="large"></ion-icon>
                <ion-text color="light">
                    {{$t('Search')}}
                </ion-text>
            </ion-button>
        </ion-fab>
    </div>
</template>


<script setup lang="ts">
    import { backspace, close, informationCircle, searchOutline, searchSharp, star } from 'ionicons/icons';
    import { nextTick, ref, onMounted } from 'vue';
    import { locationAPI } from '@/libs/backend';
    import { inject } from 'vue'
    import { roadbookEditStore } from '@/stores/RoadbookEditStore';
    import { useRouter } from 'vue-router';
    import { MapStore } from '@/stores/mapStore';
    import { favoritePlaceStore } from '@/stores/FavoritePlaceStore'
    import NominatimLocation  from '@/components/geocoder/NominatimLocation.vue'

    const router = useRouter();
    const isMobile=inject('isMobile')
    const isDesktop=inject('isDesktop')

    defineExpose({
        dismiss,
        open,
    });

    // Component event emission
    const emit = defineEmits<{
        (e: 'focusPlace', data:any): void
    }>();
    
    // Store
    const favoritePlaces = favoritePlaceStore();
    // HTML Element refs
    const searchSheet = ref()
    const searchBar = ref()
    // Variable refs
    const sheetSegment = ref('search')
    const favorites:any = ref([])
    const searchValue = ref('')
    const roadbookStore = roadbookEditStore();
    const mapStore = MapStore();
    const searchHeight = ref((window.innerHeight*0.5) - 100) // 0.5 is the default breakpoint
    var loading = ref(false)
    var query = ref('')

    onMounted(async()=>{
        favoritePlaces.init()
    });

    function open(){
        searchSheet.value.$el.present();
    }

    function dismiss(){
        nextTick(()=> {
            searchSheet.value.$el.dismiss();
        })
    }

    function focusSearchBar(){
        // only works when Modal opening it terminated with modal @didPresent event
        searchBar.value.$el.setFocus()
    }

    async function search(event:any) {
        loading.value = true;
        query.value = event.target.value.toLowerCase();
        mapStore.searchResults = await locationAPI.search(query.value);
        loading.value = false;
        searchValue.value = event.target.value;
    }

    function focusPlace(result:any){
        mapStore.focusedPlace = result;
        emit('focusPlace', result);
        dismiss();
    }

    function setStart(searchResult:any, e:Event){
        e.stopPropagation()
        dismiss();
        mapStore.resetFocus();
        roadbookStore.setStart([searchResult.lat, searchResult.lon])
    }
    
    function setDestination(searchResult:any, e:Event){
        e.stopPropagation()
        dismiss();
        mapStore.resetFocus();
        roadbookStore.setDestination([searchResult.lat, searchResult.lon])
    }
    
    function addWaypoint(searchResult:any, e:Event){
        e.stopPropagation()
        dismiss();
        mapStore.resetFocus();
        roadbookStore.addStep([searchResult.lat, searchResult.lon])
    }
    
    router.beforeResolve(()=>{
        if(searchSheet.value) dismiss();
    })

    function changeHeight(e:any){
        searchHeight.value = (window.innerHeight * e.detail.breakpoint) - 100 // 100px is the height of modal header
    }

</script>

<style scoped>
@media (min-width: 992px) {
    ion-modal {
        left: calc(-100% + 600px)
    }
}
.align-end{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

ion-header,
ion-header ion-toolbar {
    --min-height: 50px !important;
    --max-height: 50px !important;
    --height: 50px !important;
}

.search-content {
    overflow: scroll
}
</style>