import { defineStore } from 'pinia';

export const AuthStore = defineStore({
    id: 'auth',
    actions: {
        token(){
            return localStorage.getItem('auth_token');
        },
        setToken(token:string) {
            localStorage.setItem('auth_token', token);
        },
        userData(){
            let data:string|null = localStorage.getItem('userdata')
            if(data) return JSON.parse(data);
            else return undefined
        },
        setUserData(userdata:any) {
            localStorage.setItem('userdata', JSON.stringify(userdata));
        },
        clear() {
            localStorage.removeItem('auth_token');
            localStorage.removeItem('userdata');
        }
    }
});